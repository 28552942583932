import { BsBank2, BsBarChartLineFill, BsGearFill, BsPersonFill, BsServer, BsClipboard2Fill } from "react-icons/bs";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaRoute, FaRegMoneyBillAlt, FaTruck, FaBox, FaHandHoldingUsd, FaCar } from "react-icons/fa"

const Sidebar = ()=>{
    return (
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <a href="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src="/images/logo.png" alt="" height={22} />
            </span>
            <span className="logo-lg">
              <img src="/images/logo.png" alt="" height={17} />
            </span>
          </a>
          {/* Light Logo*/}
          <a href="/" className="logo logo-light">
            <span className="logo-sm">
              <img src="/images/logo.png" alt="" height={22} />
            </span>
            <span className="logo-lg">
              <img src="/images/logo.png" alt="" height={17} />
            </span>
          </a>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line" />
          </button>
        </div>
        <div id="scrollbar" className="overflow-auto h-100">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="nav-item">
                <a className="nav-link menu-link" href="/">
                  <BsBarChartLineFill className="fs-4" />{" "}
                  <span data-key="t-widgets">Dashboard</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link menu-link"
                  href="#sidebarDashboards"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
                  <BsGearFill className="fs-4" />{" "}
                  <span data-key="t-dashboards">Config</span>
                </a>
                <div
                  className="collapse menu-dropdown ps-3"
                  id="sidebarDashboards"
                >
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a
                        href="/bank"
                        className="nav-link"
                        data-key="t-analytics"
                      >
                        <BsBank2 className="fs-5" /> Bank{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/product-category"
                        className="nav-link"
                        data-key="t-analytics"
                      >
                        <FaBox className="fs-5" /> Product Category{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/customer-service"
                        className="nav-link"
                        data-key="t-crm"
                      >
                        <RiCustomerService2Fill className="fs-5" /> Customer
                        Service{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/drop-type"
                        className="nav-link"
                        data-key="t-ecommerce"
                      >
                        <FaRoute className="fs-5" /> Drop Type{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/tax" className="nav-link" data-key="t-crypto">
                        <FaRegMoneyBillAlt className="fs-5" /> Tax{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/vehicle-type"
                        className="nav-link"
                        data-key="t-projects"
                      >
                        <FaTruck className="fs-5" /> Vehicle Type{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/vehicle-brand"
                        className="nav-link"
                        data-key="t-nft"
                      >
                        <FaTruck className="fs-5" /> Vehicle Brand{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="/term-of-payment"
                        className="nav-link"
                        data-key="t-nft"
                      >
                        <FaHandHoldingUsd className="fs-5" /> Term Of Payment{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>{" "}
              {/* end Dashboard Menu */}
              <li className="nav-item">
                <a
                  className="nav-link menu-link"
                  href="#master-data"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="master-data"
                >
                  <BsServer className="fs-4" />{" "}
                  <span data-key="t-apps">Master Data</span>
                </a>
                <div className="collapse menu-dropdown" id="master-data">
                  <ul className="nav flex-column ps-3">
                    <li className="nav-item">
                      <a
                        href="/armada"
                        className="nav-link"
                        data-key="t-calendar"
                      >
                        <FaTruck className="fs-4" /> Armada{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/driver" className="nav-link" data-key="t-chat">
                        <FaCar className="fs-4" /> Driver{" "}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/user" className="nav-link" data-key="t-chat">
                        <BsPersonFill className="fs-4" /> User{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link menu-link"
                  href="#report"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="report"
                >
                  <BsClipboard2Fill className="fs-4" />{" "}
                  <span data-key="t-apps">Report</span>
                </a>
                <div className="collapse menu-dropdown" id="report">
                  <ul className="nav flex-column ps-3">
                    <li className="nav-item">
                      <a
                        href="/pengiriman"
                        className="nav-link"
                        data-key="t-chat"
                      >
                        <FaBox className="fs-4" /> Pengiriman{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
        </div>
        <div className="sidebar-background" />
      </div>
    );
}

export default Sidebar;