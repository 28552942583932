import { useEffect, useState } from "react"
import { useFormik } from "formik";
import { editAsuransi } from "../../../Service/MasterData/DeliveryService";
import { useSelector } from "react-redux";

const EditModalAsuransi = (props) => {
    const {token} = useSelector((state)=>state.TokenReducer)
    const header = {
        headers: { 'Authorization': token }
    }
    const formik = useFormik({
        initialValues:{
            id:props.data.id,
            asuransi_value:props.data.asuransi_value,
        },
        enableReinitialize:true
    })

    const handleChange = (e)=>{
        formik.setFieldValue(e.target.name,e.target.value)
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        const data = await editAsuransi(formik.values,header)
        if (data) {
            // Hide modal with vanila JS
            document.getElementById("editModalAsuransi").classList.remove('show')
            document.getElementById("editModalAsuransi").setAttribute('aria-hidden','true')
            document.getElementById("editModalAsuransi").setAttribute('style','display:none')
            document.body.removeChild(document.getElementsByClassName('modal-backdrop')[0]);
            document.body.style.removeProperty("overflow");
            document.body.style.removeProperty("padding-right");
            document.body.className = '';

            props.getData()
        }
    }

    return (
        <div className="modal fade" id="editModalAsuransi" tabIndex={-1} aria-labelledby="editModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="editModalLabel">Edit Asuransi</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <form onSubmit={(e)=>{handleSubmit(e)}}>
                        {/* <input name="id" type="hidden" value={formik.values.id} /> */}
                        <div className="mb-3">
                            <label className="form-label">Asuransi Persentase</label>
                            <input type="number"step="0.01" min={0} name="asuransi_value" value={formik.values.asuransi_value} onChange={(e)=>{handleChange(e)}} className="form-control" required/>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}

export default EditModalAsuransi