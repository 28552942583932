import { useFormik } from "formik";
import { createTax } from "../../../Service/Config/TaxService";
import { useSelector } from "react-redux";

const CreateModalTax = (props) => {
    const {token} = useSelector((state)=>state.TokenReducer)
    const header = {
        headers: { 'Authorization': token }
    }
    const formik = useFormik({
        initialValues:{
            kd_pajak:null,
            nama_pajak:null,
            value:null,
            keterangan:null,
        },
        enableReinitialize:true
    })

    const handleChange = (e)=>{
        formik.setFieldValue(e.target.name,e.target.value)
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        const data = await createTax(formik.values,header)
        if (data) {
            // Hide modal with vanila JS
            document.getElementById("createModal").classList.remove('show')
            document.getElementById("createModal").setAttribute('aria-hidden','true')
            document.getElementById("createModal").setAttribute('style','display:none')
            document.body.removeChild(document.getElementsByClassName('modal-backdrop')[0]);
            document.body.style.removeProperty("overflow");
            document.body.style.removeProperty("padding-right");
            document.body.className = '';

            props.getData()
        }
    }

    return (
        <div className="modal fade" id="createModal" tabIndex={-1} aria-labelledby="createModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="createModalLabel">Create Tax</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <form onSubmit={(e)=>{handleSubmit(e)}}>
                        <div className="mb-3">
                            <label className="form-label">Kode Pajak</label>
                            <input type="text" name="kd_pajak" value={formik.values.kd_pajak} onChange={(e)=>{handleChange(e)}} className="form-control" required/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Nama Pajak</label>
                            <input type="text" name="nama_pajak" value={formik.values.nama_pajak} onChange={(e)=>{handleChange(e)}} className="form-control" required/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Nilai Pajak</label>
                            <input type="text" name="value" value={formik.values.value} onChange={(e)=>{handleChange(e)}} className="form-control" pattern="[\d\.\d]+" required/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Keterangan</label>
                            <input type="text" name="keterangan" value={formik.values.keterangan} onChange={(e)=>{handleChange(e)}} className="form-control" required/>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}

export default CreateModalTax