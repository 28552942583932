import axios from "axios";
import Swal from "sweetalert2";

const ROOT_API = process.env.REACT_APP_API_URL;

export async function getDelivery(params) {
    const URL = `api/master-data/pengiriman/list`;

    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;

    return axiosResponse.data;
}

export async function getDeliveryDetail(params) {
    const URL = `api/master-data/pengiriman/${params.params.id}/detail`;

    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;

    return axiosResponse.data;
}

export async function uploadBuktiTrf(data, header) {
    const URL = `api/file/order/upload-bukti-transfer`;

    const response = await axios.post(`${ROOT_API}${URL}`, data, header).then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'Berhasil upload bukti transfer.',
        })
        return true;
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'Terjadi error',
            text: err?.response?.data?.message ?? ''
        })
        return false;
    })

    return response;
}

export async function approveBayar(data, header) {
    const URL = `api/master-data/pengiriman/approve-bukti-bayar`;

    const response = await axios.post(`${ROOT_API}${URL}`, data, header).then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'success',
            text: "Success"
        })
        return true;
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'error',
            text: err?.response?.data?.message ?? "Error"
        })
        return false;
    })

    return response;
}

export async function rejectBayar(data, header) {
    const URL = `api/master-data/pengiriman/reject-bukti-bayar`;

    const response = await axios.post(`${ROOT_API}${URL}`, data, header).then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'success',
            text: "Success"
        })
        return true;
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'error',
            text: err?.response?.data?.message ?? "Error"
        })
        return false;
    })

    return response;
}

export async function uploadQuotation(data, header) {
    const URL = `api/master-data/pengiriman/upload-quotation`;
    console.log(data);

    const response = await axios.post(`${ROOT_API}${URL}`, data, header).then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'Berhasil upload bukti transfer.',
        })
        return true;
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'Terjadi error',
            text: err?.response?.data?.message ?? ''
        })
        return false;
    })

    return response;
}

export async function editAsuransi(data, header) {
    const URL = `api/master-data/pengiriman/edit-asuransi`;

    const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'success',
            text: "Success"
        })
        return true;
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'error',
            text: err?.response?.data?.message ?? "Error"
        })
        return false;
    })

    return response;
}

export async function addCost(data, header) {
    const URL = `api/master-data/pengiriman/add-cost`;

    const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
        Swal.fire({
            icon: 'success',
            title: 'success',
            text: "Success"
        })
        return true;
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: 'error',
            text: err?.response?.data?.message ?? "Error"
        })
        return false;
    })

    return response;
}