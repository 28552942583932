import { useState } from "react"
import axios from 'axios';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom"
import Footer from "../Components/Layout/Footer";
import { useDispatch } from "react-redux";
import { token_action } from "../Redux/Action";
const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [payload, setPayload] = useState({
        username: null,
        password: null
    })
    const handlePayload = (e) => {
        setPayload({
            ...payload,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_URL + 'api/admin/login', payload,
        ).then((data) => {
            dispatch(token_action({ email: data.data.email, role_name: data.data.role_name ,token: data.data.token }))
            navigate("/")
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'error',
                text: err?.response?.data?.message ?? "Error"
            })
        })
    }

    return (
        <div className="auth-page-wrapper pt-5">
            <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                <div className="bg-overlay" />
            </div>
            <div className="auth-page-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card mt-4">
                                <div className="card-body p-4">
                                    <div className="text-center mt-2">
                                        <a href="index.html" className="d-inline-block auth-logo mb-4 mt-4">
                                            <img src="/images/logo.png" alt="logo" height={100} />
                                        </a>
                                        <h5 className="text-primary">Sign in to continue!</h5>
                                    </div>
                                    <div className="p-2 mt-4">
                                        <form onSubmit={(e) => handleSubmit(e)}>
                                            <div className="mb-3">
                                                <label htmlFor="username" className="form-label">Username</label>
                                                <input onKeyUp={handlePayload} type="text" className="form-control" id="username" name="username" placeholder="Enter username" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="password-input">Password</label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <input onKeyUp={handlePayload} type={show ? 'text' : 'password'} className="form-control pe-5 password-input" name="password" placeholder="Enter password" id="password-input" />
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setShow(!show)}><i className="ri-eye-fill align-middle" /></button>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <button className="btn btn-success w-100" type="submit">Sign In</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login