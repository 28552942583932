import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import Select from 'react-select';
import Pagination from "../../../Components/Pagination";
import Breadcrumb from "../../../Components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getDelivery } from "../../../Service/MasterData/DeliveryService";

const Armada = () => {
    const navigate = useNavigate()
    const { token } = useSelector((state) => state.TokenReducer)
    const [totalData, setTotalData] = useState(0)
    const [deliveryData, setDeliveryData] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [param, setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            search: null
        }
    })
    let configs = param

    const handleParam = (e) => {
        configs.params = {
            ...configs.params,
            [e.target.name]: e.target.value
        }
        getData()
    }
    const handleSelect = (e, name) => {
        configs.params = {
            ...configs.params,
            [name]: e.value
        }
        getData()
    }

    const getData = async () => {
        const data = await getDelivery(configs)
        const delivery = data.rows.map((v, k) => {
            return {
                id: v.id,
                no_order: v.no_order,
                transporter: v.order_transporter?.nama,
                shipper: v.order_shipper.nama,
                vehicle: v.order_vehicle?.no_polisi,
                drop_type: v.drop_type_nama,
                payment_status: v.order_stat_pay.status_name,
                order_status: v.order_status.status_name
            }
        })
        setDeliveryData(delivery)
        setTotalData(data.count)
    }

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <Breadcrumb 
                                title='Pengiriman'
                                subtitle={[{ name: 'Report' },{ name: 'Pengiriman' },]}
                                level={1}
                            />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="customerList">
                                            <div className="row g-4 mb-3">
                                                <div className="col-3">
                                                    <div>
                                                        <div className="search-box ms-2">
                                                            <input type="text" onChange={(e) => { handleParam(e) }} name="search" className="form-control search" placeholder="Search..." />
                                                            <i className="ri-search-line search-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <Select
                                                        placeholder="Filter"
                                                        name="filter"
                                                        options={[
                                                            { value: null, label: ' All ' },
                                                            { value: 'Menunggu Konfirmasi', label: 'Menunggu Konfirmasi' },
                                                            { value: 'Sedang Diproses', label: 'Sedang Diproses' },
                                                            { value: 'Barang Sedang Dikirim', label: 'Barang Sedang Dikirim' },
                                                            { value: 'Barang Telah Sampai', label: 'Barang Telah Sampai' },
                                                            { value: 'Selesai', label: 'Selesai' },
                                                            { value: 'Ditolak', label: 'Ditolak' },
                                                        ]}
                                                        onChange={(e) => {
                                                            handleSelect(e, 'filter')
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm">
                                                    {/* <div className="d-flex justify-content-sm-end">
                                                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createModal"><i className="ri-add-line align-bottom me-1" /> Tambah</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="table-responsive table-card mx-2 mt-3 mb-1">
                                                <table className="table align-middle table-nowrap" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Nomor Pengiriman</th>
                                                            <th>Transporter</th>
                                                            <th>Shipper</th>
                                                            <th>Armada</th>
                                                            <th>Tipe</th>
                                                            <th>Status Pengiriman</th>
                                                            <th>Status Pembayaran</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {
                                                            deliveryData?.map((v, k) => {
                                                                return (
                                                                    <tr key={k}>
                                                                        <td><span className="text-primary" style={{cursor:'pointer'}} onClick={() => navigate(`/pengiriman/${v.id}/detail`)}>{v.no_order}</span></td>
                                                                        <td>{v.transporter}</td>
                                                                        <td>{v.shipper}</td>
                                                                        <td>{v.vehicle}</td>
                                                                        <td>{v.drop_type}</td>
                                                                        <td>{v.order_status}</td>
                                                                        <td>{v.payment_status}</td>
                                                                        {/* <td>{(v.status_id === 1) ? "Active" : "Inactive"}</td> */}
                                                                        {/* <td>
                                                                            <div className="d-flex gap-2">
                                                                                <div>
                                                                                    <button type="button" className="btn btn-sm btn-primary edit-item-btn" onClick={() => { handleEditData(v) }} data-bs-toggle="modal" data-bs-target="#editModal">Edit</button>
                                                                                </div>
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" role="switch" onChange={(e) => { changeStatus(e, v.id_vehicle) }} checked={(v.status_id === 1) ? true : false} />
                                                                                </div>
                                                                            </div>
                                                                        </td> */}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Pagination
                                                totalData={totalData}
                                                params={param}
                                                handleParam={handleParam}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Armada