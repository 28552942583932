import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import Select from 'react-select';
import Pagination from "../../../Components/Pagination";
import { editStatusArmada, getArmada } from "../../../Service/MasterData/ArmadaService";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../Components/Breadcrumb";

const Armada = () => {
    const navigate = useNavigate()
    const { token } = useSelector((state) => state.TokenReducer)
    const [vehicleData, setVehicleData] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [param, setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            search: null
        }
    })
    let configs = param
    let header = {
        headers: { 'Authorization': token },
    }

    const changeStatus = async (e, id) => {
        await editStatusArmada({ id_vehicle: id, status_id: (e.target.checked) ? 1 : 2 }, header)
        getData()
    }

    const handleParam = (e) => {
        configs.params = {
            ...configs.params,
            [e.target.name]: e.target.value
        }
        getData()
    }
    const handleSelect = (e, name) => {
        configs.params = {
            ...configs.params,
            [name]: e.value
        }
        getData()
    }

    const getData = async () => {
        const bank = await getArmada(configs)
        setVehicleData(bank)
    }

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <Breadcrumb 
                                title='Armada'
                                subtitle={[{ name: 'Master Data' },{ name: 'Armada' },]}
                                level={1}
                            />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="customerList">
                                            <div className="row g-4 mb-3">
                                                <div className="col-sm-auto">
                                                    <div>
                                                        <div className="search-box ms-2">
                                                            <input type="text" onChange={(e) => { handleParam(e) }} name="search" className="form-control search" placeholder="Search..." />
                                                            <i className="ri-search-line search-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-auto">
                                                    <Select
                                                        placeholder="Filter"
                                                        name="filter"
                                                        options={[
                                                            { value: null, label: ' All ' },
                                                            { value: '1', label: 'Active' },
                                                            { value: '2', label: 'Inactive' },
                                                        ]}
                                                        onChange={(e) => {
                                                            handleSelect(e, 'filter')
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-sm">
                                                    {/* <div className="d-flex justify-content-sm-end">
                                                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createModal"><i className="ri-add-line align-bottom me-1" /> Tambah</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="table-responsive table-card mx-2 mt-3 mb-1">
                                                <table className="table align-middle table-nowrap" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Nomor Polisi</th>
                                                            <th>Transporter</th>
                                                            <th>Vendor</th>
                                                            <th>Available</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {
                                                            vehicleData?.rows?.map((v, k) => {
                                                                return (
                                                                    <tr key={k}>
                                                                        <td><span className="text-primary" style={{cursor:'pointer'}} onClick={() => navigate(`/armada/${v.id_vehicle}/detail`)}>{v.no_polisi}</span></td>
                                                                        <td>{v.vehicle_user.nama}</td>
                                                                        <td>{v.vehicle_vendor ? v.vehicle_vendor?.nama_vendor : ''}</td>
                                                                        <td>{v.is_available}</td>
                                                                        <td>{(v.status_id === 1) ? "Active" : "Inactive"}</td>
                                                                        <td>
                                                                            <div className="d-flex gap-2">
                                                                                {/* <div>
                                                                                    <button type="button" className="btn btn-sm btn-primary edit-item-btn" onClick={() => { handleEditData(v) }} data-bs-toggle="modal" data-bs-target="#editModal">Edit</button>
                                                                                </div> */}
                                                                                <div className="form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" role="switch" onChange={(e) => { changeStatus(e, v.id_vehicle) }} checked={(v.status_id === 1) ? true : false} />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Pagination
                                                totalData={vehicleData.count}
                                                params={param}
                                                handleParam={handleParam}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Armada