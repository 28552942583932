
export const token_action = data => dispatch => {
    dispatch({
        type : "ACT_LOGIN",
        payload : data
    })
}

export const role_action = data => dispatch => {
    dispatch({
        type : "ROLE_NAME_LOGIN",
        payload : data
    })
}