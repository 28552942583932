import { useEffect, useState } from "react"
import { useFormik } from "formik";
import { createBank } from "../../../Service/Config/BankService";
import { useSelector } from "react-redux";

const CreateModalBank = (props) => {
    const {token} = useSelector((state)=>state.TokenReducer)
    const header = {
        headers: { 'Authorization': token }
    }
    const formik = useFormik({
        initialValues:{
            bank_nama:null,
        },
        enableReinitialize:true
    })

    const handleChange = (e)=>{
        formik.setFieldValue(e.target.name,e.target.value)
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        const data = await createBank(formik.values,header)
        if (data) {
            // Hide modal with vanila JS
            document.getElementById("createModal").classList.remove('show')
            document.getElementById("createModal").setAttribute('aria-hidden','true')
            document.getElementById("createModal").setAttribute('style','display:none')
            document.body.removeChild(document.getElementsByClassName('modal-backdrop')[0]);
            document.body.style.removeProperty("overflow");
            document.body.style.removeProperty("padding-right");
            document.body.className = '';

            props.getData()
        }
    }

    return (
        <div className="modal fade" id="createModal" tabIndex={-1} aria-labelledby="createModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="createModalLabel">Create Bank</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <form onSubmit={(e)=>{handleSubmit(e)}}>
                        <div className="mb-3">
                            <label className="form-label">Nama Bank</label>
                            <input type="text" name="bank_nama" value={formik.values.bank_nama} onChange={(e)=>{handleChange(e)}} className="form-control" required/>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}

export default CreateModalBank