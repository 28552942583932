const Breadcrumb = ({title, subtitle, level}) => {
    return (
        <div className="col-12 mb-3">
            <h4 className="mb-sm-0 mb-1">{title}</h4>
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    {subtitle.map((v, k) => {
                        return (
                            <li className={`breadcrumb-item ${level === k && `active`}`}>{v.name}</li>
                        )
                    })}
                </ol>
            </div>
        </div>
    )
}

export default Breadcrumb