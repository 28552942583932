import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { getDriverDetail, getDriverOrder } from "../../../Service/Report/DriverService";
import { useSelector } from "react-redux";
// import EditModalTax from "./EditModalTax";
// import CreateModalTax from "./CreateModalTax";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import Pagination from "../../../Components/Pagination";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const DriverOrder = ()=>{
    const {token} = useSelector((state)=>state.TokenReducer)
    const [orderData, setOrderData] = useState([])
    const {driver_id} = useParams()
    const [driver, setDriver] = useState({
        id: null,
        kode_driver: '',
        nama_driver: '',
        no_hp: '',
        no_ktp: '',
        vendor_id: null,
        vendor_name: '',
        vendor_pemilik: '',
        no_sim: '',
        lisence_driver: '',
        profile_pic: '',
        ktp_pic: '',
        sim_pic: '',
        latitude: '',
        longitude: ''
    })
    const navigate = useNavigate()
    const [param,setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            driver_id:driver_id,
            search:null
        }
    })
    let configs = param
    let header = {
        headers: { 'Authorization': token },
    }

    const handleParam = (e) =>{
        configs.params = {
            ...configs.params,
            [e.target.name]:e.target.value
        }
        getData()
    }
    const handleSelect = (e, name) =>{
        configs.params = {
            ...configs.params,
            [name]:e.value
        }
        getData()
    }

    const getData = async()=>{
        const data = await getDriverOrder(configs)
        setOrderData(data)
    }

    const getDetail = async () => {
        const data = await getDriverDetail({
            headers: { 'Authorization': token },
            params: {
                driver_id:driver_id,
            }
        })
        console.log(data)
        setDriver(data)
    }

    useEffect(()=>{
        getData()
        getDetail()
    },[])
    return(
    <>
        <Navbar/>
        <Sidebar/>
<div className="main-content">
  <div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 mb-3">
                <h4 className="mb-sm-0 mb-1">Driver</h4>
                <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Master Data</li>
                    <li className="breadcrumb-item active">Driver</li>
                    <li className="breadcrumb-item active">Detail Driver</li>
                </ol>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div id="customerList">
                            <div className="row g-4 mb-3">
                                <div className="col-sm-auto">
                                    <div className="row">
                                        {/* <img style={{width:150}} src={userData?.profile_pic} className="img-thumbnail" alt="Profile PIC" />
                                        <p class="text-xl-start">{userData?.nama}</p> */}
                                        <img style={{width:150}} src={driver.profile_pic} className="img-thumbnail" alt="Profile PIC" />
                                        <p class="text-xl-start">{driver.nama_driver}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pengiriman-tab" data-bs-toggle="pill" data-bs-target="#pills-pengiriman" type="button" role="tab" aria-controls="pills-pengiriman" aria-selected="true">Pengiriman</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="posisi-tab" data-bs-toggle="pill" data-bs-target="#pills-posisi" type="button" role="tab" aria-controls="pills-posisi" aria-selected="false">Posisi</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-pengiriman" role="tabpanel" aria-labelledby="pills-pengiriman" tabIndex={0}>
                                    <div id="customerList">
                                        <div className="row g-4 mb-3">
                                            <div className="col-sm-auto">
                                                <div>
                                                    <div className="search-box ms-2">
                                                        <input type="text" onChange={(e)=>{handleParam(e)}} name="search" className="form-control search" placeholder="Search..." />
                                                        <i className="ri-search-line search-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-auto">
                                            <Select
                                                placeholder="Filter"
                                                name="filter"
                                                options={[
                                                    {value: null, label: ' All '},
                                                    {value: 'true', label: 'Active'},
                                                    {value: 'false', label: 'Inactive'},
                                                ]}
                                                onChange={(e) => {
                                                    handleSelect(e,'filter')
                                                }}
                                            />
                                            </div>
                                        </div>
                                        <div className="table-responsive table-card mx-2 mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Nomor Pengiriman</th>
                                                    <th>Transporter</th>
                                                    <th>Shipper</th>
                                                    <th>Vendor</th>
                                                    <th>Armada</th>
                                                    <th>Tipe</th>
                                                    <th>Status Pengiriman</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {
                                                    orderData?.rows?.map((v,k)=>{
                                                        return(
                                                        <tr key={k}>
                                                            <td onClick={()=>{navigate(`/driver/order/tracking/${driver_id}/${v.order_id}`)}} className="text-primary cursor-pointer">{v.no_order}</td>
                                                            <td>{v.order_transporter?.nama}</td>
                                                            <td>{v.order_shipper?.nama}</td>
                                                            <td>{v.order_vehicle?.vehicle_vendor?.nama_vendor}</td>
                                                            <td>{v.order_vehicle?.no_polisi}</td>
                                                            <td>{v.drop_type?.nama}</td>
                                                            <td>{v.order_status?.status_name}</td>
                                                        </tr> 
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            </table>
                                        </div>
                                        <Pagination 
                                            totalData = {orderData.count}
                                            params = {param}
                                            handleParam = {handleParam}
                                        />
                                    </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-posisi" role="tabpanel" aria-labelledby="pills-posisi" tabIndex={0}>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <iframe title="map" width={1460} height={600} src={`https://maps.google.com/maps?q=${driver.latitude},${driver.longitude}&hl=en&z=15&output=embed`}></iframe>
                                            </div>
                                        </div>
                                        {
                                            driver.latitude !== '' && driver.longitude !== '' ?
                                            (
                                            <div className="row mb-3">
                                                <div className="col-6">
                                                    <div className="row mb-3">
                                                        <div className="col-1 h4">{String.fromCodePoint(0x1f4cd)}</div>
                                                        <div className="col-11 h4">{driver.latitude}, {driver.longitude}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) : (
                                            <div className="row mb-3">
                                                <div className="col-6">
                                                    <div className="h4">No location set</div>
                                                </div>
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <Footer/>
</div>
    </>
    )
}

export default DriverOrder