import axios from "axios";
import Swal from "sweetalert2";

const ROOT_API = process.env.REACT_APP_API_URL;


export async function getVehicleBrand(params) {
    const URL = `api/config/vehicle-brand/list`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function editVehicleBrand(data, header) {
  const URL = `api/config/vehicle-brand/update`;

  const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}

export async function createVehicleBrand(data, header) {
  const URL = `api/config/vehicle-brand/create`;

  const response = await axios.post(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}

export async function editStatusVehicleBrand(data, header) {
  const URL = `api/config/vehicle-brand/change-status`;

  const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}