import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { getDriverDetail, getOrderLatLong } from "../../../Service/Report/DriverService";
import { useSelector } from "react-redux";
import moment from "moment";
// import EditModalTax from "./EditModalTax";
// import CreateModalTax from "./CreateModalTax";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import Pagination from "../../../Components/Pagination";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const DriverOrderTracking = ()=>{
    const {token} = useSelector((state)=>state.TokenReducer)
    const [orderData, setOrderData] = useState([])
    const {order_id, driver_id} = useParams()
    const [driver, setDriver] = useState({
        id: null,
        kode_driver: '',
        nama_driver: '',
        no_hp: '',
        no_ktp: '',
        vendor_id: null,
        vendor_name: '',
        vendor_pemilik: '',
        no_sim: '',
        lisence_driver: '',
        profile_pic: '',
        ktp_pic: '',
        sim_pic: '',
        latitude: '',
        longitude: ''
    })
    const navigate = useNavigate()
    const [param,setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            order_id:order_id,
            search:null
        }
    })
    let configs = param
    let header = {
        headers: { 'Authorization': token },
    }

    const handleParam = (e) =>{
        configs.params = {
            ...configs.params,
            [e.target.name]:e.target.value
        }
        getData()
    }
    const handleSelect = (e, name) =>{
        configs.params = {
            ...configs.params,
            [name]:e.value
        }
        getData()
    }

    const getData = async()=>{
        const data = await getOrderLatLong(configs)
        setOrderData(data)
    }

    const getDetail = async () => {
        const data = await getDriverDetail({
            headers: { 'Authorization': token },
            params: {
                driver_id:driver_id,
            }
        })
        console.log(data)
        setDriver(data)
    }

    useEffect(()=>{
        getData()
        getDetail()
    },[])
    return (
      <>
        <Navbar />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mb-3">
                  <h4 className="mb-sm-0 mb-1">Driver</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Master Data</li>
                      <li className="breadcrumb-item active">Driver</li>
                      <li className="breadcrumb-item active">Detail Driver</li>
                      <li className="breadcrumb-item active">Detail Riwayat</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div id="customerList">
                        <div className="row g-4 mb-3">
                          <div className="col-sm-auto">
                            <div className="row">
                              {/* <img style={{width:150}} src={userData?.profile_pic} className="img-thumbnail" alt="Profile PIC" />
                                        <p class="text-xl-start">{userData?.nama}</p> */}
                              <img
                                style={{ width: 150 }}
                                src={driver.profile_pic}
                                className="img-thumbnail"
                                alt="Profile PIC"
                              />
                              <p class="text-xl-start">{driver.nama_driver}</p>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                          <iframe
                            title="map"
                            width={1460}
                            height={600}
                            src={`https://maps.google.com/maps?q=${orderData[0]?.lat}, ${orderData[0]?.long}&hl=en&z=15&output=embed`}
                          ></iframe>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            {orderData?.map((v, k) => {
                              return (
                                <div className="row mb-3">
                                  <div className="col-1 h4">
                                    {String.fromCodePoint(0x1f4cd)}
                                  </div>
                                  <div className="col-11 h4">
                                    {v.lat}, {v.long};{" "}
                                    {moment(v.created_at).format(
                                      "DD-MM-YYYY hh:mm:ss A"
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer">
                    <button
                      className="btn btn-secondary"
                      onClick={() => navigate(-1)}
                    >
                      Kembali
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
}

export default DriverOrderTracking