import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import PrivateRoute from "./utils/PrivateRoute";
import Login from './Pages/Login';
import Register from './Pages/Register';
import Notfound from './Pages/Notfound';
import Dashboard from './Pages/Dashboard';
import Bank from './Pages/Config/Bank/Bank';
import CustomerService from './Pages/Config/Customer-Service/CustomerService';
import DropType from './Pages/Config/Drop-Type/DropType';
import Tax from './Pages/Config/Tax/Tax';
import VehicleType from './Pages/Config/Vehicle-Type/VehicleType';
import VehicleBrand from './Pages/Config/Vehicle-Brand/VehicleBrand';
import User from './Pages/MasterData/User/User';
import UserDetail from './Pages/MasterData/User/UserDetail';
import Armada from './Pages/MasterData/Armada/Armada';
import ArmadaDetail from './Pages/MasterData/Armada/ArmadaDetail';
import Delivery from './Pages/MasterData/Delivery/Delivery';
import DeliveryDetail from './Pages/MasterData/Delivery/DeliveryDetail';

import axios from "axios";
import { useDispatch } from "react-redux";
import { token_action } from './Redux/Action';
import ProductCategory from './Pages/Config/Product-Category/ProductCategory';
import TermOfPayment from './Pages/Config/Term-Of-Payment/TermOfPayment';
import Driver from './Pages/Report/Driver/Driver';
import DriverOrder from './Pages/Report/Driver/DriverOrder';
import DriverOrderTracking from './Pages/Report/Driver/DriverOrderTracking';

function App() {
    const dispatch = useDispatch()
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response?.status === 401) {
            const data = {
                id: null,
                email: null,
                token: null,
            }
            dispatch(token_action(data));
        }
        return error;
    });
    return (
        <Router>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route element={<PrivateRoute />}>
                    <Route element={<Dashboard />} path='/' exact />
                    <Route element={<Bank />} path='/bank' exact />
                    <Route element={<ProductCategory />} path='/product-category' exact />
                    <Route element={<CustomerService />} path='/customer-service' exact />
                    <Route element={<DropType />} path='/drop-type' exact />
                    <Route element={<Tax />} path='/tax' exact />
                    <Route element={<VehicleType />} path='/vehicle-type' exact />
                    <Route element={<VehicleBrand />} path='/vehicle-brand' exact />
                    <Route element={<TermOfPayment />} path='/term-of-payment' exact />
                    <Route element={<User />} path='/user' exact />
                    <Route element={<UserDetail />} path='/user/:id/detail' exact />
                    <Route element={<Driver />} path='/driver' exact />
                    <Route element={<DriverOrder />} path='/driver/:driver_id/order' exact />
                    <Route element={<DriverOrderTracking />} path='/driver/order/tracking/:driver_id/:order_id' exact />
                    <Route element={<Armada />} path='/armada' exact />
                    <Route element={<ArmadaDetail />} path='/armada/:id/detail' exact />
                    <Route element={<Delivery />} path='/pengiriman' exact />
                    <Route element={<DeliveryDetail />} path='/pengiriman/:id/detail' exact />
                </Route>
                <Route path='*' element={<Notfound />} />
            </Routes>
        </Router>
    )
}

export default App;
