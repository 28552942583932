import axios from "axios";
import Swal from "sweetalert2";

const ROOT_API = process.env.REACT_APP_API_URL;


export async function getDriver(params) {
    const URL = `api/master-data/driver/list`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function getDriverOrder(params) {
    const URL = `api/master-data/driver/order-list`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function getDriverDetail(params) {
    const URL = `api/tp/driver/detail`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function getOrderLatLong(params) {
    const URL = `api/master-data/driver/order-lat-long`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}