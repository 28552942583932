import Navbar from "../../../Components/Layout/Navbar";
import Sidebar from "../../../Components/Layout/Sidebar";
import Footer from "../../../Components/Layout/Footer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "../../../Components/Pagination";
import Breadcrumb from "../../../Components/Breadcrumb";
import CreateModalProductCategory from "./CreateModalProductCategory";
import Select from "react-select";
import {
  createProductCategory,
  editProductCategory,
  editStatusProductCategory,
  getProductCategory,
} from "../../../Service/Config/ProductCategoryService";
import EditModalProductCategory from "./EditModalProductCategory";

const ProductCategory = () => {
  const { token } = useSelector((state) => state.TokenReducer);
  const [productCategory, setProductCategory] = useState([]);
  const [editDataProductCategory, setEditDataProductCategory] = useState({});
  const [param, setParam] = useState({
    headers: { Authorization: token },
    params: {
      limit: 10,
      page: 1,
      search: null,
    },
  });
  let configs = param;
  let header = {
    headers: { Authorization: token },
  };

  const handleParam = (e) => {
    configs.params = {
      ...configs.params,
      [e.target.name]: e.target.value,
    };
    console.log(configs);
    getData();
  };

  const handleEditData = (data) => {
    setEditDataProductCategory({
      id_ktg_barang: data.id_ktg_barang,
      jenis_barang: data.jenis_barang,
      status_id: data.status_id,
    });
  };

  const changeStatus = async (e, id) => {
    await editStatusProductCategory(
      { id_ktg_barang: id, status_id: e.target.checked ? 1 : 2 },
      header
    );
    getData();
  };

  const getData = async () => {
    const data = await getProductCategory(configs);
    setProductCategory(data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <Breadcrumb
                title="Product category"
                subtitle={[
                  { name: "Configuration" },
                  { name: "Product category" },
                ]}
                level={1}
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div id="customerList">
                      <div className="row g-4 mb-3">
                        <div className="col-sm-auto">
                          <div>
                            <div className="search-box ms-2">
                              <input
                                type="text"
                                onChange={(e) => {
                                  handleParam(e);
                                }}
                                name="search"
                                className="form-control search"
                                placeholder="Search..."
                              />
                              <i className="ri-search-line search-icon" />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <Select
                            placeholder="Filter"
                            name="filter"
                            options={[
                              { value: null, label: " All " },
                              { value: "1", label: "Active" },
                              { value: "2", label: "Inactive" },
                            ]}
                            onChange={(e) => {
                              const request = {
                                target: {
                                  name: "filter",
                                  value: e.value,
                                },
                              };
                              handleParam(request);
                            }}
                          />
                        </div>
                        <div className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#createModal"
                            >
                              <i className="ri-add-line align-bottom me-1" />{" "}
                              Tambah
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive table-card mx-2 mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th>Jenis Barang</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {productCategory?.rows?.map((v, k) => {
                              return (
                                <tr key={k}>
                                  <td>{v.jenis_barang}</td>
                                  <td>
                                    {v.status_id == 1 ? "Active" : "Inactive"}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div>
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-primary edit-item-btn"
                                          onClick={() => {
                                            handleEditData(v);
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target="#editModal"
                                        >
                                          Edit
                                        </button>
                                      </div>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          onChange={(e) => {
                                            changeStatus(e, v.id_ktg_barang);
                                          }}
                                          checked={
                                            v.status_id == 1 ? true : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <Pagination
                        totalData={productCategory.count}
                        params={param}
                        handleParam={handleParam}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <CreateModalProductCategory getData={getData} />
      <EditModalProductCategory
        // handlemodal={handleEditModal}
        getData={getData}
        data={editDataProductCategory}
        // resetid={ () => { setId() } }
      />
    </>
  );
};

export default ProductCategory;
