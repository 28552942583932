import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { createCustomerService, getCustomerService } from "../../../Service/Config/CustomerService";
import { useSelector } from "react-redux";
import { useFormik } from "formik";

const CustomerService = ()=>{
    const {token} = useSelector((state)=>state.TokenReducer)
    let header = {
        headers: { 'Authorization': token },
    }
    const formik = useFormik({
        initialValues:{
            id:null,
            nama:null,
            value:null,
        },
        enableReinitialize:true
    })

    const getData = async()=>{
        const data = await getCustomerService(header)
            formik.setFieldValue("id",data.id)
            formik.setFieldValue("nama",data.nama)
            formik.setFieldValue("value",data.value)
    }

    const handleParam = (e)=>{
        formik.setFieldValue(e.target.name,e.target.value)
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        const data = await createCustomerService(formik.values,header)
        if (data) {
            getData()
        }
    }

    useEffect(()=>{
        getData()
    },[])
    return(
    <>
        <Navbar/>
        <Sidebar/>
<div className="main-content">
  <div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 mb-3">
                <h4 className="mb-sm-0 mb-1">Customer Service</h4>
                <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Configuration</li>
                    <li className="breadcrumb-item active">Customer Service</li>
                </ol>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nomor:</label>
                            <input type="text" name="value" onChange={(e)=>{handleParam(e)}} value={formik.values.value} className="form-control" aria-describedby="emailHelp" />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <Footer/>
</div>
        {/* <EditModalBank
            // handlemodal={handleEditModal}
            getData={getData}
            data={editDataBank}
            // resetid={ () => { setId() } }
        />
        <CreateModalBank 
            getData={getData}
        /> */}
    </>
    )
}

export default CustomerService