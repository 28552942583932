import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Breadcrumb from "../../../Components/Breadcrumb";
import { approveBayar, getDeliveryDetail, rejectBayar, uploadBuktiTrf, uploadQuotation } from "../../../Service/MasterData/DeliveryService";
import { BsCheckCircle, BsDownload, BsPencil } from 'react-icons/bs'
import Swal from "sweetalert2";
import EditModalAsuransi from "./EditModalAsuransi";
import AddCostModal from "./AddCostModal";

const DeliveryDetail = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { token } = useSelector((state) => state.TokenReducer)
    const [deliveryData, setDeliveryData] = useState()
    const [SelectedFile, setSelectedFile] = useState()
    const [dataAsuransi, setDataAsuransi] = useState({
        id:null,
        asuransi_value:null
    })
    const [dataAddCost, setDataAddCost] = useState({
        id:null,
        muat:null,
        bongkar:null,
        biaya_inap:null,
        biaya_lain:null,
        ket_biaya_lain:null,
    })
    // eslint-disable-next-line no-unused-vars
    const [param, setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            id: id
        }
    })
    let configs = param
    let header = {
        headers: { 'Authorization': token },
    }

    const handleApprove = async () => {
        Swal.fire({
            icon: 'question',
            text: 'Apakah anda yakin untuk meng-approve Bukti Pembayaran?',
            showConfirmButton: true,
            confirmButtonText: 'Konfirmasi',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            customClass: {
                confirmButton: 'bg-primary',
                cancelButton: 'bg-secondary'
            }
        }).then(async (result) => {
            if (result.value) {
                await approveBayar({ id: id }, header)
                getData()
            }
        })
    }

    const handleReject = async () => {
        Swal.fire({
            icon: 'question',
            text: 'Apakah anda yakin untuk me-reject Bukti Pembayaran?',
            showConfirmButton: true,
            confirmButtonText: 'Konfirmasi',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            customClass: {
                confirmButton: 'bg-danger',
                cancelButton: 'bg-secondary'
            }
        }).then(async (result) => {
            if (result.value) {
                await rejectBayar({ id: id }, header)
                getData()
            }
        })

    }

    const handleUploadBuktiTrf = async (e) => {
        const file = e.target.files[0]
        if (file.size <= 10485760) {
            setSelectedFile(file)
            const formData = new FormData()
            if (file) {formData.append('file', file)}
            formData.append('order_id', id)
            const upload = await uploadBuktiTrf(formData, header)
            if (upload) {
                getData()
            } else {
                setSelectedFile()
            }
        } else {
            Swal.fire({
                icon: 'warning',
                text: 'File size exceeds 10MB.',
                customClass: {
                    confirmButton: 'bg-secondary',
                }
            });
            e.preventDefault()
        }
    }
    
    const handleDownload = async (img, no_order, type) => {
        const image = await fetch(img)
        const imgBlob = await image.blob()
        const imageURL = URL.createObjectURL(imgBlob)

        const link = document.createElement('a')
        link.href = imageURL
        link.download = `${type}_${no_order}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const getData = async () => {
        const data = await getDeliveryDetail(configs)
        setDeliveryData(data)
    }

    const handleUploadQuotation = async (e) => {
        const file = e.target.files[0]
        console.log(e);
        if (file.size <= 10485760) {
            setSelectedFile(file)
            const formData = new FormData()
            if (file) {formData.append('image', file)}
            formData.append('id', id)
            const upload = await uploadQuotation(formData, header)
            if (upload) {
                getData()
            }
        } else {
            Swal.fire({
                icon: 'warning',
                text: 'File size exceeds 10MB.',
                customClass: {
                    confirmButton: 'bg-secondary',
                }
            });
            e.preventDefault()
        }
    }

    const handleEditAsuransi = async (data) => {
        setDataAsuransi({
            id:data.order.id,
            asuransi_value:data.order.asuransi_value
        })
    }

    const handleAddCost = async (data) => {
        setDataAddCost({
            id:data.order.id,
            muat:data.order.muat,
            bongkar:data.order.bongkar,
            biaya_inap:data.order.biaya_inap,
            biaya_lain:data.order.biaya_lain,
            ket_biaya_lain:data.order.ket_biaya_lain,
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <Navbar />
        <Sidebar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <Breadcrumb
                  title="Pengiriman"
                  subtitle={[
                    { name: "Report" },
                    { name: "Pengiriman" },
                    { name: "Detail Pengiriman" },
                  ]}
                  level={2}
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row border-bottom border-black mb-3 pb-3">
                        <div className="col-6">
                          <img
                            className="w-25"
                            src={
                              deliveryData?.order?.order_vehicle?.vehicle_pic
                            }
                            alt=""
                          />
                          <h2>
                            {deliveryData?.order?.order_vehicle?.no_polisi}
                          </h2>
                        </div>
                        <div className="col-6 d-flex align-items-end">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            disabled
                          >
                            {deliveryData?.order?.no_order} -{" "}
                            {deliveryData?.order?.drop_type?.nama} -{" "}
                            {deliveryData?.order?.order_status?.status_name}
                          </button>
                        </div>
                      </div>
                      <div className="row p-3">
                        <ul class="nav nav-pills" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="general-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#general"
                              type="button"
                              role="tab"
                              aria-controls="general"
                              aria-selected="true"
                            >
                              General
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="addcost-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#addcost"
                              type="button"
                              role="tab"
                              aria-controls="addcost"
                              aria-selected="false"
                            >
                              Add Cost
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="quotation-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#quotation"
                              type="button"
                              role="tab"
                              aria-controls="quotation"
                              aria-selected="false"
                            >
                              Quotation
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="payment-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#payment"
                              type="button"
                              role="tab"
                              aria-controls="payment"
                              aria-selected="false"
                            >
                              Bukti Pembayaran
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="transfer-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#transfer"
                              type="button"
                              role="tab"
                              aria-controls="transfer"
                              aria-selected="false"
                            >
                              Bukti Transfer
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="cutoff-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#cutoff"
                              type="button"
                              role="tab"
                              aria-controls="cutoff"
                              aria-selected="false"
                            >
                              Bukti Potong
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="tax-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#tax"
                              type="button"
                              role="tab"
                              aria-controls="tax"
                              aria-selected="false"
                            >
                              Faktur Pajak
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div
                            class="tab-pane active"
                            id="general"
                            role="tabpanel"
                            aria-labelledby="general-tab"
                            tabindex="0"
                          >
                            <div className="row mt-3">
                              <div className="col-4">
                                <label
                                  for="tanggal_pengiriman"
                                  class="form-label"
                                >
                                  Tanggal Pengiriman
                                </label>
                                <input
                                  id="tanggal_pengiriman"
                                  type="text"
                                  class="form-control"
                                  value={moment(
                                    deliveryData?.order?.tanggal_pengiriman
                                  ).format("DD-MM-YYYY")}
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <label
                                  for="tanggal_barang_sampai"
                                  class="form-label"
                                >
                                  Tanggal Barang Sampai
                                </label>
                                <input
                                  id="tanggal_barang_sampai"
                                  type="text"
                                  class="form-control"
                                  value={moment(
                                    deliveryData?.order?.estimasi_sampai
                                  ).format("DD-MM-YYYY")}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label
                                  for="tarif_pengiriman"
                                  class="form-label"
                                >
                                  Tarif Pengiriman
                                </label>
                                <input
                                  id="tarif_pengiriman"
                                  type="text"
                                  class="form-control"
                                  value={deliveryData?.order?.grand_total}
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <label
                                  for="status_pembayaran"
                                  class="form-label"
                                >
                                  Status Pembayaran
                                </label>
                                <input
                                  id="status_pembayaran"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.order?.order_stat_pay
                                      ?.status_name
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="asuransi" class="form-label">
                                  Asuransi
                                </label>
                                <div className="d-flex">
                                  <input
                                    id="asuransi"
                                    type="text"
                                    class="form-control"
                                    value={deliveryData?.order?.asuransi_value}
                                    readOnly
                                  />
                                  <button
                                    type="button"
                                    className="ms-2 btn btn-primary"
                                    onClick={() => {
                                      handleEditAsuransi(deliveryData);
                                    }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#editModalAsuransi"
                                  >
                                    <BsPencil />
                                  </button>
                                </div>
                              </div>
                              <div className="col-4">
                                <label
                                  for="status_pembayaran"
                                  class="form-label"
                                >
                                  Metode Pembayaran
                                </label>
                                <input
                                  id="status_pembayaran"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.order?.term_of_payment
                                      ?.nama_perjanjian
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label
                                  for="area_penjemputan"
                                  class="form-label"
                                >
                                  Area Penjemputan
                                </label>
                                <input
                                  id="area_penjemputan"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.order?.odr_almt_jemput
                                      ?.address_kab?.name
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <label for="area_pengiriman" class="form-label">
                                  Area Pengiriman
                                </label>
                                <input
                                  id="area_pengiriman"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.odr_almt_pengiriman[0]
                                      ?.address_kab?.name
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label
                                  for="alamat_penjemputan"
                                  class="form-label"
                                >
                                  Alamat Penjemputan
                                </label>
                                <input
                                  id="alamat_penjemputan"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.order?.odr_almt_jemput?.alamat
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <label
                                  for="alamat_pengiriman"
                                  class="form-label"
                                >
                                  Alamat Pengiriman
                                </label>
                                <input
                                  id="alamat_pengiriman"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.odr_almt_pengiriman[0]?.alamat
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="info_pengirim" class="form-label">
                                  Info Pengirim
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      deliveryData?.order?.order_pengirim?.nama
                                    }
                                    readOnly
                                  />
                                  <span class="input-group-text">-</span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      deliveryData?.order?.order_pengirim?.telp
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="col-4">
                                <label for="info_penerima" class="form-label">
                                  Info Penerima
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      deliveryData?.order?.order_penerima?.nama
                                    }
                                    readOnly
                                  />
                                  <span class="input-group-text">-</span>
                                  <input
                                    type="text"
                                    class="form-control"
                                    value={
                                      deliveryData?.order?.order_penerima?.telp
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label
                                  for="alamat_penjemputan"
                                  class="form-label"
                                >
                                  Transporter
                                </label>
                                <input
                                  id="alamat_penjemputan"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.order?.order_transporter?.nama
                                  }
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <label
                                  for="alamat_pengiriman"
                                  class="form-label"
                                >
                                  Shipper
                                </label>
                                <input
                                  id="alamat_pengiriman"
                                  type="text"
                                  class="form-control"
                                  value={
                                    deliveryData?.order?.order_shipper?.nama
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="driver" class="form-label">
                                  Driver
                                </label>
                                {deliveryData?.driver.length > 0 ? (
                                  deliveryData?.driver.map((v, k) => {
                                    return (
                                      <input
                                        id="driver"
                                        type="text"
                                        class="form-control"
                                        value={v?.nama}
                                        readOnly
                                      />
                                    );
                                  })
                                ) : (
                                  <input
                                    id="driver"
                                    type="text"
                                    class="form-control"
                                    value={""}
                                    readOnly
                                  />
                                )}
                              </div>
                              <div className="col-4">
                                <label for="barang" class="form-label">
                                  Barang
                                </label>
                                {deliveryData?.order?.barang_orders.length >
                                0 ? (
                                  deliveryData?.order?.barang_orders.map(
                                    (v, k) => {
                                      return (
                                        <input
                                          id="barang"
                                          type="text"
                                          class="form-control"
                                          value={v?.barang_order?.nama}
                                          readOnly
                                        />
                                      );
                                    }
                                  )
                                ) : (
                                  <input
                                    id="barang"
                                    type="text"
                                    class="form-control"
                                    value={""}
                                    readOnly
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane active"
                            id="addcost"
                            role="tabpanel"
                            aria-labelledby="addcost-tab"
                            tabindex="0"
                          >
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="muat" class="form-label">
                                  Muat
                                </label>
                                <input
                                  id="muat"
                                  type="number"
                                  class="form-control"
                                  value={deliveryData?.order?.muat}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="bongkar" class="form-label">
                                  Bongkar
                                </label>
                                <input
                                  id="bongkar"
                                  type="number"
                                  class="form-control"
                                  value={deliveryData?.order?.bongkar}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="biaya_inap" class="form-label">
                                  Biaya Inap
                                </label>
                                <input
                                  id="biaya_inap"
                                  type="number"
                                  class="form-control"
                                  value={deliveryData?.order?.biaya_inap}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="ket_biaya_lain" class="form-label">
                                  Biaya Lain-lain
                                </label>
                                <input
                                  id="ket_biaya_lain"
                                  type="number"
                                  class="form-control"
                                  value={deliveryData?.order?.biaya_lain}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-4">
                                <label for="ket_biaya_lain" class="form-label">
                                  Keterangan Biaya Lain-lain
                                </label>
                                <input
                                  id="ket_biaya_lain"
                                  type="text"
                                  class="form-control"
                                  value={deliveryData?.order?.ket_biaya_lain}
                                  readOnly
                                />
                              </div>
                            </div>
                            <br />
                            {deliveryData?.order?.order_stat_pay?.status_name ==
                              "Menunggu Kalkulasi" && (
                              <button
                                type="button"
                                className="ms-2 btn btn-primary"
                                onClick={() => {
                                  handleAddCost(deliveryData);
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#addModalCost"
                              >
                                {" "}
                                Edit{" "}
                              </button>
                            )}
                          </div>
                          <div
                            className="tab-pane"
                            id="quotation"
                            role="tabpanel"
                            aria-labelledby="quotation-tab"
                            tabindex="0"
                          >
                            {deliveryData?.order?.quotation_file ? (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <img
                                      src={deliveryData?.order?.quotation_file}
                                      alt="transfer"
                                      className="img-thumbnail w-25"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col d-flex justify-content-center">
                                    <button
                                      onClick={() =>
                                        handleDownload(
                                          deliveryData?.order?.quotation_file,
                                          deliveryData?.order?.no_order,
                                          "quotation"
                                        )
                                      }
                                      className="btn btn-primary mx-2"
                                    >
                                      Download <BsDownload />
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      class="bi bi-image-alt w-25 opacity-25"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                                    </svg>
                                  </div>
                                </div>
                                <div className="row text-center">
                                  <p>
                                    *Belum ada Quotation, mohon upload quotation
                                    yang telah ditandatangani
                                  </p>
                                  <div className="row">
                                    <div className="col d-flex justify-content-center">
                                      <input
                                        type="file"
                                        id="upload-trf"
                                        accept="image/jpeg,image/png"
                                        onChange={(e) =>
                                          handleUploadQuotation(e)
                                        }
                                        hidden
                                      />
                                      <label
                                        htmlFor="upload-trf"
                                        className="btn btn-primary mx-2"
                                      >
                                        Upload Quotation
                                      </label>
                                    </div>
                                  </div>
                                  {/* <div className="d-flex justify-content-center">
                                                                        <input type="file" onChange={handleUploadFile} />
                                                                    </div> */}
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            class="tab-pane"
                            id="payment"
                            role="tabpanel"
                            aria-labelledby="payment-tab"
                            tabindex="0"
                          >
                            {deliveryData?.order?.order_bukti_bayar
                              ?.image_url ? (
                              <>
                                <div className="row">
                                  <div className="col-3 pt-3"></div>
                                  <div className="col-6 p-3 d-flex justify-content-center">
                                    <img
                                      src={
                                        deliveryData?.order?.order_bukti_bayar
                                          ?.image_url
                                      }
                                      alt="payment"
                                      className="img-thumbnail"
                                    />
                                  </div>
                                  <div className="col-3 pt-3">
                                    {deliveryData?.order?.order_stat_pay
                                      ?.status_name === "Terverifikasi" && (
                                      <button
                                        className="btn btn-outline-primary btn-sm"
                                        disabled
                                      >
                                        Approved <BsCheckCircle />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col d-flex justify-content-center">
                                    <button
                                      onClick={() =>
                                        handleDownload(
                                          deliveryData?.order?.order_bukti_bayar
                                            ?.image_url,
                                          deliveryData?.order?.no_order,
                                          "bukti_pembayaran"
                                        )
                                      }
                                      className="btn btn-primary mx-2"
                                    >
                                      Download <BsDownload />
                                    </button>
                                    {deliveryData?.order?.order_stat_pay
                                      ?.status_name !== "Terverifikasi" &&
                                      deliveryData?.order?.order_stat_pay
                                        ?.status_name !== "Ditolak" && (
                                        <>
                                          <button
                                            className="btn btn-primary mx-2"
                                            onClick={handleApprove}
                                          >
                                            Approve Bukti Pembayaran
                                          </button>
                                          <button
                                            className="btn btn-primary mx-2"
                                            onClick={handleReject}
                                          >
                                            Reject Bukti Pembayaran
                                          </button>
                                        </>
                                      )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      class="bi bi-image-alt w-25 opacity-25"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                                    </svg>
                                  </div>
                                </div>
                                <div className="row text-center">
                                  <p>Belum ada bukti bayar, mohon menunggu.</p>
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            class="tab-pane"
                            id="transfer"
                            role="tabpanel"
                            aria-labelledby="transfer-tab"
                            tabindex="0"
                          >
                            {deliveryData?.order?.order_bukti_tp?.image_url ? (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <img
                                      src={
                                        deliveryData?.order?.order_bukti_tp
                                          ?.image_url
                                      }
                                      alt="transfer"
                                      className="img-thumbnail w-25"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col d-flex justify-content-center">
                                    <input
                                      type="file"
                                      id="upload-trf"
                                      accept="image/jpeg,image/png"
                                      onChange={(e) => handleUploadBuktiTrf(e)}
                                      hidden
                                    />
                                    <label
                                      htmlFor="upload-trf"
                                      className="btn btn-primary mx-2"
                                    >
                                      Upload Bukti Transfer
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    {SelectedFile ? (
                                      <img
                                        alt=""
                                        src={URL.createObjectURL(SelectedFile)}
                                        className="w-25"
                                      />
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        class="bi bi-image-alt w-25 opacity-25"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                                      </svg>
                                    )}
                                  </div>
                                </div>
                                <div className="row text-center">
                                  <p>
                                    Belum ada bukti transfer, mohon upload bukti
                                    transfer yang sah.
                                  </p>
                                </div>
                                <div className="row">
                                  <div className="col d-flex justify-content-center">
                                    <input
                                      type="file"
                                      id="upload-trf"
                                      accept="image/jpeg,image/png"
                                      onChange={(e) => handleUploadBuktiTrf(e)}
                                      hidden
                                    />
                                    <label
                                      htmlFor="upload-trf"
                                      className="btn btn-primary mx-2"
                                    >
                                      Upload Bukti Transfer
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            class="tab-pane"
                            id="cutoff"
                            role="tabpanel"
                            aria-labelledby="cutoff-tab"
                            tabindex="0"
                          >
                            {deliveryData?.order?.order_bukti_potong
                              ?.image_url ? (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <img
                                      src={
                                        deliveryData?.order?.order_bukti_potong
                                          ?.image_url
                                      }
                                      alt="transfer"
                                      className="img-thumbnail w-25"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col d-flex justify-content-center">
                                    <button
                                      onClick={() =>
                                        handleDownload(
                                          deliveryData?.order
                                            ?.order_bukti_potong?.image_url,
                                          deliveryData?.order?.no_order,
                                          "bukti_potong"
                                        )
                                      }
                                      className="btn btn-primary mx-2"
                                    >
                                      Download <BsDownload />
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      class="bi bi-image-alt w-25 opacity-25"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                                    </svg>
                                  </div>
                                </div>
                                <div className="row text-center">
                                  <p>Belum ada bukti potong, mohon menunggu.</p>
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            class="tab-pane"
                            id="tax"
                            role="tabpanel"
                            aria-labelledby="tax-tab"
                            tabindex="0"
                          >
                            {deliveryData?.order?.order_faktur_pajak
                              ?.image_url ? (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <img
                                      src={
                                        deliveryData?.order?.order_faktur_pajak
                                          ?.image_url
                                      }
                                      alt="transfer"
                                      className="img-thumbnail w-25"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col d-flex justify-content-center">
                                    <button
                                      onClick={() =>
                                        handleDownload(
                                          deliveryData?.order
                                            ?.order_faktur_pajak?.image_url,
                                          deliveryData?.order?.no_order,
                                          "faktur_pajak"
                                        )
                                      }
                                      className="btn btn-primary mx-2"
                                    >
                                      Download <BsDownload />
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col p-3 d-flex justify-content-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                      class="bi bi-image-alt w-25 opacity-25"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5l-4.777-3.947z" />
                                    </svg>
                                  </div>
                                </div>
                                <div className="row text-center">
                                  <p>Belum ada faktur pajak, mohon menunggu.</p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button
                        className="btn btn-secondary"
                        onClick={() => navigate("/pengiriman")}
                      >
                        Kembali
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <EditModalAsuransi getData={getData} data={dataAsuransi} />
        <AddCostModal
          getData={getData}
          data={dataAddCost}
          deliveryData={deliveryData}
        />
      </>
    );
}

export default DeliveryDetail