const Notfound = ()=>{
    return(
    <div className="auth-page-wrapper pt-5">
    <div className=" auth-one-bg" id="auth-particles">
        <div className="bg-overlay" />
    </div>
    <div className="auth-page-content">
        <div className="container">
        <div className="row">
            <div className="col-lg-12">
            <div className="text-center pt-5">
                <div className="mt-n4 pt-5">
                <h1 className="display-1 fw-medium pt-5">404</h1>
                <h3 className="text-uppercase">Sorry, Page not Found 😭</h3>
                <p className="text-muted mb-4">The page you are looking for not available!</p>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    )
}

export default Notfound