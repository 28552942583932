import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { getArmadaDetail } from "../../../Service/MasterData/ArmadaService";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Breadcrumb from "../../../Components/Breadcrumb";

const ArmadaDetail = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { token } = useSelector((state) => state.TokenReducer)
    const [vehicleData, setVehicleData] = useState({
        id_vehicle:'',
        no_polisi:'',
        vehicle_pic:'',
        no_stnk:'',
        no_stnk_expire:'',
        no_kir:'',
        no_kir_expire:'',
        muatan_maksimum:'',
        p_dimensi:'',
        t_dimensi:'',
        l_dimensi:'',
        vehicle_type:'',
        vehicle_brand:''
    })
    // eslint-disable-next-line no-unused-vars
    const [param, setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            id_vehicle: id
        }
    })
    let configs = param

    const getData = async () => {
        const data = await getArmadaDetail(configs)
        setVehicleData({
            id_vehicle:data.id_vehicle,
            vehicle_pic:data.vehicle_pic,
            no_polisi:data.no_polisi,
            no_stnk:data.no_stnk,
            no_stnk_expire:data.no_stnk_expire,
            no_kir:data.no_kir,
            no_kir_expire:data.no_kir_expire,
            muatan_maksimum:data.muatan_maksimum,
            p_dimensi:data.p_dimensi,
            t_dimensi:data.t_dimensi,
            l_dimensi:data.l_dimensi,
            vehicle_type:data.vehicle_type.jenis_kendaraan,
            vehicle_brand:data.vehicle_brand.brand_name
        })
    }

    useEffect(() => {
        getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <Breadcrumb 
                                title='Armada'
                                subtitle={[{ name: 'Master Data' },{ name: 'Armada' },{ name: 'Detail Armada' },]}
                                level={2}
                            />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <img className="w-25" src={vehicleData.vehicle_pic} alt={`armada-${id}`} />
                                        </div>
                                        <div className="row">
                                            <h2>{vehicleData.no_polisi}</h2>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label for="vehicle_type" class="form-label">Tipe Kendaraan</label>
                                                <input id="vehicle_type" type="text" class="form-control" value={vehicleData.vehicle_type} readOnly />
                                            </div>
                                            <div className="col">
                                                <label for="muatan_maksimum" class="form-label">Muatan</label>
                                                <input id="muatan_maksimum" type="text" class="form-control" value={`${vehicleData.muatan_maksimum} kg`} readOnly />
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label for="vehicle_brand" class="form-label">Merk</label>
                                                <input id="vehicle_type" type="text" class="form-control" value={vehicleData.vehicle_brand} readOnly />
                                            </div>
                                            <div className="col">
                                                <label for="dimensi" class="form-label">Dimensi</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" value={`${vehicleData.p_dimensi} cm`} readOnly />
                                                    <span class="input-group-text">×</span>
                                                    <input type="text" class="form-control" value={`${vehicleData.l_dimensi} cm`} readOnly />
                                                    <span class="input-group-text">×</span>
                                                    <input type="text" class="form-control" value={`${vehicleData.t_dimensi} cm`} readOnly />
                                                </div>
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label for="no_stnk" class="form-label">No. STNK</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" value={vehicleData.no_stnk} readOnly />
                                                    <span class="input-group-text">-</span>
                                                    <input type="text" class="form-control" value={moment(vehicleData.no_stnk_expire).format('DD/MM/YYYY')} readOnly />
                                                </div>
                                            </div>
                                            <div className="col">
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label for="no_kir" class="form-label">No. KIR</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" value={vehicleData.no_kir} readOnly />
                                                    <span class="input-group-text">-</span>
                                                    <input type="text" class="form-control" value={moment(vehicleData.no_kir_expire).format('DD/MM/YYYY')} readOnly />
                                                </div>
                                            </div>
                                            <div className="col">
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button className="btn btn-secondary" onClick={() => navigate('/armada')}>Kembali</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ArmadaDetail