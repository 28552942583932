import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { detailUser } from "../../../Service/MasterData/UserService";
import { useSelector } from "react-redux";
import Select from 'react-select';
import Pagination from "../../../Components/Pagination";
import { useParams } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaImage } from "react-icons/fa";

const User = ()=>{
    const {token} = useSelector((state)=>state.TokenReducer)
    const {id} = useParams()
    const [userData, setUserData] = useState(null)
    const [vendor, setVendor] = useState([])
    let header = {
        headers: { 'Authorization': token },
    }
    const getData = async()=>{
        const data = await detailUser(id,header)
        setUserData(data.data)
        setVendor(data.vendor)
    }

    useEffect(()=>{
        getData()
    },[])
    return(
    <>
        <Navbar/>
        <Sidebar/>
<div className="main-content">
  <div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 mb-3">
                <h4 className="mb-sm-0 mb-1">User</h4>
                <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Master Data</li>
                    <li className="breadcrumb-item active">Detail User</li>
                </ol>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div id="customerList">
                            <div className="row g-4 mb-3">
                                <div className="col-sm-auto">
                                    <div className="row">
                                        <img style={{width:150}} src={userData?.profile_pic} className="img-thumbnail" alt="Profile PIC" />
                                        <p class="text-xl-start">{userData?.nama}</p>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="d-flex justify-content-sm-end">
                                        <button type="button" className="btn btn-outline-primary" disabled>{(userData?.activation)?"Active":"Nonactive"} | {(userData?.validated)?"Validate":"Not Validate"}</button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="general-tab" data-bs-toggle="pill" data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general" aria-selected="true">General</button>
                                    </li>
                                    {
                                        userData?.role_id === 1 && (
                                            <>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="driver-tab" data-bs-toggle="pill" data-bs-target="#pills-driver" type="button" role="tab" aria-controls="pills-driver" aria-selected="false">Driver</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="vendor-tab" data-bs-toggle="pill" data-bs-target="#pills-vendor" type="button" role="tab" aria-controls="pills-vendor" aria-selected="false">Vendor</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="nomor-rekening-tab" data-bs-toggle="pill" data-bs-target="#pills-nomor-rekening" type="button" role="tab" aria-controls="pills-nomor-rekening" aria-selected="false">Nomor Rekening</button>
                                            </li>
                                    </>
                                        )
                                    }
                                    
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="dokumen-perijinan-tab" data-bs-toggle="pill" data-bs-target="#pills-dokumen-perijinan" type="button" role="tab" aria-controls="pills-dokumen-perijinan" aria-selected="false">Dokumen Perijinan</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pic-tab" data-bs-toggle="pill" data-bs-target="#pills-pic" type="button" role="tab" aria-controls="pills-pic" aria-selected="false">PIC</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-general" role="tabpanel" aria-labelledby="pills-general" tabIndex={0}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Email</label>
                                                    <input value={userData?.email} className="form-control" disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. Handphone</label>
                                                    <input className="form-control" value={userData?.nohp} disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="pt-3">Alamat Kantor</h2>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Alamat</label>
                                                    <input className="form-control" value={userData?.profile_tp?.alamat} disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputPassword1" className="form-label">Provinsi</label>
                                                    <input className="form-control" value={userData?.profile_tp?.profile_prov?.name} disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputPassword1" className="form-label">Kota/Kabupaten</label>
                                                    <input className="form-control" value={userData?.profile_tp?.profile_kab?.name} disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputPassword1" className="form-label">Kecamatan</label>
                                                    <input className="form-control" value={userData?.profile_tp?.profile_kec?.name} disabled />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Kelurahan</label>
                                                    <input className="form-control" value={userData?.profile_tp?.profile_kel?.name} disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputPassword1" className="form-label">Kode Pos</label>
                                                    <input className="form-control" value={userData?.profile_tp?.kode_pos} disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputPassword1" className="form-label">Alamat Lengkap</label>
                                                    <input className="form-control" value={userData?.profile_tp?.alamat_lengkap} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-driver" role="tabpanel" aria-labelledby="pills-driver" tabIndex={0}>
                                    {
                                        userData && (
                                            <BootstrapTable
                                                keyField="id"
                                                data={ userData?.user_driver }
                                                columns={ [{
                                                        dataField:"nama_driver",
                                                        text:"nama"
                                                    },{
                                                        dataField:"no_hp",
                                                        text:"No Handphone"
                                                    },{
                                                        dataField:"driver_vendor.nama_vendor",
                                                        text:"Vendor",
                                                    },{
                                                        dataField:"status_id",
                                                        text:"Status",
                                                        formatter:(cell,row,rowIndex, data)=>{
                                                            return ((cell == 1)?"Active":"Inactive")
                                                        }
                                                    }] 
                                                }
                                                bordered={ false }
                                                pagination={ paginationFactory() }
                                            />
                                        )
                                    }
                                    </div>
                                    <div className="tab-pane fade" id="pills-vendor" role="tabpanel" aria-labelledby="pills-vendor" tabIndex={0}>
                                    {
                                        userData && (
                                            <BootstrapTable
                                                keyField="id"
                                                data={ vendor }
                                                columns={ [{
                                                        dataField:"nama_vendor",
                                                        text:"Nama"
                                                    },{
                                                        dataField:"nama_pemilik",
                                                        text:"Pemilik"
                                                    },{
                                                        dataField:"status",
                                                        text:"Status",
                                                        formatter:(cell,row,rowIndex, data)=>{
                                                            return ((cell)?"Active":"Inactive")
                                                        }
                                                    }]
                                                }
                                                bordered={ false }
                                                pagination={ paginationFactory() }
                                            />
                                        )
                                    }
                                    </div>
                                    <div className="tab-pane fade" id="pills-nomor-rekening" role="tabpanel" aria-labelledby="pills-nomor-rekening" tabIndex={0}>
                                    {
                                        userData && (
                                            <BootstrapTable
                                                keyField="id"
                                                data={ userData?.user_account_number_tp }
                                                columns={ [{
                                                        dataField:"no_rekening",
                                                        text:"Nomor Eekening"
                                                    },{
                                                        dataField:"atas_nama",
                                                        text:"Pemilik"
                                                    },{
                                                        dataField:"bank.bank_nama",
                                                        text:"Bank",
                                                    }] 
                                                }
                                                bordered={ false }
                                                pagination={ paginationFactory() }
                                            />
                                        )
                                    }
                                    
                                    </div>
                                    <div className="tab-pane fade" id="pills-dokumen-perijinan" role="tabpanel" aria-labelledby="pills-dokumen-perijinan" tabIndex={0}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Tipe Dokumen</label>
                                                    <input className="form-control" value={userData?.document_tp.business_type.nama} disabled />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. KTP</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.ktp} disabled/>
                                                        <a type="button" href={userData?.document_tp.ktp_file} target="blank" className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></a>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. NPWP</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.npwp} disabled/>
                                                        <button type="button" href={userData?.document_tp.npwp_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. SIUP</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.siup} disabled/>
                                                        <button type="button" href={userData?.document_tp.siup_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. SIUJPT</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.siujpt} disabled/>
                                                        <button type="button" href={userData?.document_tp.siujpt_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. SKT</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.skt} disabled/>
                                                        <button type="button" href={userData?.document_tp.skt_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">No. TDP/NIB</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.tdp_nib} disabled/>
                                                        <button type="button" href={userData?.document_tp.tdp_nib_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. Akta Pendirian</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.akta_pendirian} disabled/>
                                                        <button type="button" href={userData?.document_tp.akta_pendirian_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. SPPKP</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.sppkp} disabled/>
                                                        <button type="button" href={userData?.document_tp.sppkp_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">No. Surat Domisili</label>
                                                    <div className="d-flex">
                                                        <input className="form-control" value={userData?.document_tp.surat_domisili} disabled/>
                                                        <button type="button" href={userData?.document_tp.surat_domisili_file} className="btn btn-sm btn-primary edit-item-btn mx-2"><FaImage /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-pic" role="tabpanel" aria-labelledby="pills-pic" tabIndex={0}>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Nama PIC</label>
                                                <input className="form-control" value={userData?.user_pic.nama_pic} disabled />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">No KTP PIC</label>
                                                <input className="form-control" value={userData?.user_pic.ktp} disabled/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email PIC</label>
                                                <input className="form-control" value={userData?.user_pic.email} disabled/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">No Handphone PIC</label>
                                                <input className="form-control" value={userData?.user_pic.hp} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <Footer/>
</div>
    </>
    )
}

export default User