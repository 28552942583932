import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import TokenReducer from "./Reducer/TokenReducer";


const persistConfig = {
    key: "root",
    storage,
};

const middleWares = [thunk];
const rootReducer = combineReducers({TokenReducer});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const Store = createStore(persistedReducer, applyMiddleware(...middleWares));
export default Store;