import axios from "axios";

const ROOT_API = process.env.REACT_APP_API_URL;

export async function getDashboard(params) {
    const URL = `api/admin/dashboard`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}