import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import ReactECharts from "echarts-for-react"
import Select from 'react-select'
import Pagination from "../Components/Pagination";
import Navbar from "../Components/Layout/Navbar"
import Sidebar from "../Components/Layout/Sidebar"
import Footer from "../Components/Layout/Footer"
import Breadcrumb from "../Components/Breadcrumb"
import { getDashboard } from "../Service/DashboardService"
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from "date-fns";
import { interval } from "date-fns";

const Dashboard = () => {
  const { token } = useSelector((state) => state.TokenReducer);
  const [DataReady, setDataReady] = useState(false);
  const [Ongoing, setOngoing] = useState(0);
  const [Reject, setReject] = useState(0);
  const [Complete, setComplete] = useState(0);
  const [OngoingData, setOngoingData] = useState([]);
  const [RejectData, setRejectData] = useState([]);
  const [CompleteData, setCompleteData] = useState([]);
  const [DeliveryData, setDeliveryData] = useState();
  const [Time, setTime] = useState([]);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [param, setParam] = useState({
    headers: { Authorization: token },
    params: {
      filter: "This Month",
      limit: 10,
      page: 1,
    },
  });
  let configs = param;

  const now = new Date();

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(now.getFullYear(), now.getMonth(), 1),
    endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
    key: "selection",
  });

  const [open, setOpen] = useState(false);

  // const handleSelect = (e, name) => {
  //   configs.params = {
  //     ...configs.params,
  //     [name]: e.value,
  //   };
  //   getData();
  // };
  const handleSelect = () => {
    configs.params = {
      ...configs.params,
      filter: "Date range",
      start_date: formatDateRequest(selectionRange.startDate),
      end_date: formatDateRequest(selectionRange.endDate),
    };
    getData();
  }

  const handleParam = (e) => {
    configs.params = {
      ...configs.params,
      [e.target.name]: e.target.value,
    };
    getData();
  };

  const getData = async () => {
    const data = await getDashboard(configs);
    setTime(data.timeSeries);
    setReject(data.totalDitolak);
    setOngoing(data.totalPengiriman);
    setComplete(data.totalSelesai);
    setCompleteData(data.dataSeries[0].data);
    setRejectData(data.dataSeries[1].data);
    setOngoingData(data.dataSeries[2].data);
    setDeliveryData(data.data_order);
    setDataReady(true);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateRequest = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleSelectDate = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = "auto";
      const newWidth = inputRef.current.scrollWidth + 2;
      inputRef.current.style.width = `${newWidth}px`;
    }
  }, [selectionRange]);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <Breadcrumb
                title="Dashboard"
                subtitle={[{ name: "Dashboard" }]}
                level={0}
              />
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-5">
                      <div className="col-6">
                        <button
                          className="btn btn-outline-primary mx-3"
                          disabled
                        >
                          Selesai{" "}
                          <span class="badge text-bg-secondary">
                            {Complete}
                          </span>
                        </button>
                        <button className="btn btn-outline-alert mx-3" disabled>
                          Dalam Pengiriman{" "}
                          <span class="badge text-bg-secondary">{Ongoing}</span>
                        </button>
                        <button
                          className="btn btn-outline-danger mx-3"
                          disabled
                        >
                          Ditolak{" "}
                          <span class="badge text-bg-secondary">{Reject}</span>
                        </button>
                      </div>
                      <div
                        className="col-6 d-flex justify-content-end"
                        style={{ position: "relative" }}
                        ref={ref}
                      >
                        <div>
                          <input
                            ref={inputRef}
                            type="text"
                            className="form-control"
                            readOnly
                            value={`${formatDate(
                              selectionRange.startDate
                            )} - ${formatDate(selectionRange.endDate)}`}
                            onClick={() => setOpen(true)}
                          />
                        </div>

                        {open && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              right: 0,
                              zIndex: 1000,
                              backgroundColor: "white",
                              padding: 10,
                              border: "solid 1px #6a7985",
                              borderRadius: 10,
                            }}
                          >
                            <DateRangePicker
                              onChange={handleSelectDate}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              ranges={[selectionRange]}
                              months={1}
                              direction="vertical"
                            />
                            <div>
                              <button
                                className="btn btn-outline-primary"
                                onClick={() => {
                                  handleSelect();
                                  setOpen(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className="col-6 d-flex justify-content-end">
                          <Select
                            placeholder="Filter"
                            defaultValue={{
                              value: "This Month",
                              label: "This Month",
                            }}
                            options={[
                              { value: "This Day", label: "This Day" },
                              { value: "This Month", label: "This Month" },
                              { value: "This Week", label: "This Week" },
                              { value: "Last Month", label: "Last Month" },
                              { value: "Last Week", label: "Last Week" },
                            ]}
                            onChange={(e) => {
                              handleSelect(e, "filter");
                            }}
                          />
                        </div> */}
                    </div>
                    {DataReady && (
                      <>
                        <div className="row justify-content-center">
                          <div className="col">
                            <ReactECharts
                              option={{
                                legend: {
                                  data: [
                                    "Dalam Pengiriman",
                                    "Selesai",
                                    "Ditolak",
                                  ],
                                },
                                tooltip: {
                                  trigger: "axis",
                                  axisPointer: {
                                    type: "cross",
                                    label: {
                                      backgroundColor: "#6a7985",
                                    },
                                  },
                                },
                                xAxis: {
                                  name: "Tanggal",
                                  data: Time,
                                },
                                yAxis: { name: "Jumlah", interval: 1 },
                                series: [
                                  {
                                    name: "Dalam Pengiriman",
                                    type: "line",
                                    color: "orange",
                                    data: OngoingData,
                                  },
                                  {
                                    name: "Selesai",
                                    type: "line",
                                    color: "blue",
                                    data: CompleteData,
                                  },
                                  {
                                    name: "Ditolak",
                                    type: "line",
                                    color: "red",
                                    data: RejectData,
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="table-responsive table-card mx-2 mt-3 mb-1">
                            <table
                              className="table align-middle table-nowrap"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th>Nomor Pengiriman</th>
                                  <th>Transporter</th>
                                  <th>Shipper</th>
                                  <th>Armada</th>
                                  <th>Tipe</th>
                                  <th>Status Pengiriman</th>
                                  <th>Status Pembayaran</th>
                                </tr>
                              </thead>
                              <tbody className="list form-check-all">
                                {DeliveryData?.rows?.map((v, k) => {
                                  return (
                                    <tr key={k}>
                                      <td>
                                        <span
                                          className="text-primary"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            navigate(
                                              `/pengiriman/${v.id}/detail`
                                            )
                                          }
                                        >
                                          {v.no_order}
                                        </span>
                                      </td>
                                      <td>{v.transporter}</td>
                                      <td>{v.shipper}</td>
                                      <td>{v.no_polisi}</td>
                                      <td>{v.nama}</td>
                                      <td>{v.status_pengiriman}</td>
                                      <td>{v.status_pembayaran}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          {DeliveryData.count > 0 ? (
                            <Pagination
                              totalData={DeliveryData.count}
                              params={param}
                              handleParam={handleParam}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard