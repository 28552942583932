import { useFormik } from "formik";
import { createVehicleType } from "../../../Service/Config/VehicleTypeService";
import { getVehicleBrand } from "../../../Service/Config/VehicleBrandService";
import { useSelector } from "react-redux";
import AsyncSelect from 'react-select/async'

const CreateModalTax = (props) => {
    const { token } = useSelector((state) => state.TokenReducer)
    const header = {
        headers: { 'Authorization': token }
    }
    let configs = {
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            search: null
        }
    }
    const formik = useFormik({
        initialValues: {
            jenis_kendaraan: null,
            keterangan: null,
            l_dimensi: null,
            model_kendaraan: null,
            p_dimensi: null,
            t_dimensi: null,
            model_kendaraan: null,
        },
        enableReinitialize: true
    })

    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
    }
    const handleSelect = (e, name) => {
        formik.setFieldValue(name, e.brand_id)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = await createVehicleType(formik.values, header)
        if (data) {
            // Hide modal with vanila JS
            document.getElementById("createModal").classList.remove('show')
            document.getElementById("createModal").setAttribute('aria-hidden', 'true')
            document.getElementById("createModal").setAttribute('style', 'display:none')
            document.body.removeChild(document.getElementsByClassName('modal-backdrop')[0]);
            document.body.style.removeProperty("overflow");
            document.body.style.removeProperty("padding-right");
            document.body.className = '';

            formik.setFieldValue("jenis_kendaraan", "")
            formik.setFieldValue("keterangan", "")
            formik.setFieldValue("l_dimensi", "")
            formik.setFieldValue("model_kendaraan", "")
            formik.setFieldValue("p_dimensi", "")
            formik.setFieldValue("t_dimensi", "")
            formik.setFieldValue("model_kendaraan", "")

            props.getData()
        }
    }

    const optionBrand = async inputValue => {
        const data = await getVehicleBrand(configs)
        return data.rows
    }

    return (
        <div className="modal fade" id="createModal" tabIndex={-1} aria-labelledby="createModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="createModalLabel">Create Vehicle Type</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => { handleSubmit(e) }}>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Jenis Kendaraan</label>
                                        <input type="text" name="jenis_kendaraan" value={formik.values.jenis_kendaraan} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Model Kendaraan</label>
                                        <input type="text" name="model_kendaraan" value={formik.values.model_kendaraan} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Keterangan</label>
                                        <input type="text" name="keterangan" value={formik.values.keterangan} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Panjang</label>
                                        <input type="text" name="p_dimensi" value={formik.values.p_dimensi} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Lebar</label>
                                        <input type="text" name="l_dimensi" value={formik.values.l_dimensi} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Tinggi</label>
                                        <input type="text" name="t_dimensi" value={formik.values.t_dimensi} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateModalTax