import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { editStatusTax, getTax } from "../../../Service/Config/TaxService";
import { useSelector } from "react-redux";
import EditModalTax from "./EditModalTax";
import CreateModalTax from "./CreateModalTax";
import Select from 'react-select';
import Pagination from "../../../Components/Pagination";

const Bank = ()=>{
    const {token} = useSelector((state)=>state.TokenReducer)
    const [taxData, setTaxData] = useState([])
    const [editTaxData, setEditTaxData] = useState({})
    const [param,setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            search:null
        }
    })
    let configs = param
    let header = {
        headers: { 'Authorization': token },
    }
    const handleEditData = (data)=>{
        setEditTaxData({
            id_pajak:data.id_pajak,
            kd_pajak:data.kd_pajak,
            nama_pajak:data.nama_pajak,
            value:data.value,
            keterangan:data.keterangan,
            status_id:data.status_id
        })
    }

    const changeStatus = async (e,id)=>{
        await editStatusTax({id_pajak:id,status_id:(e.target.checked)?1:2}, header)
        getData()
    }

    const handleParam = (e) =>{
        configs.params = {
            ...configs.params,
            [e.target.name]:e.target.value
        }
        getData()
    }
    const handleSelect = (e, name) =>{
        configs.params = {
            ...configs.params,
            [name]:e.value
        }
        getData()
    }

    const getData = async()=>{
        const data = await getTax(configs)
            setTaxData(data)
    }

    useEffect(()=>{
        getData()
    },[])
    return(
    <>
        <Navbar/>
        <Sidebar/>
<div className="main-content">
  <div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 mb-3">
                <h4 className="mb-sm-0 mb-1">Tax</h4>
                <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Configuration</li>
                    <li className="breadcrumb-item active">Tax</li>
                </ol>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div id="customerList">
                            <div className="row g-4 mb-3">
                                <div className="col-sm-auto">
                                    <div>
                                        <div className="search-box ms-2">
                                            <input type="text" onChange={(e)=>{handleParam(e)}} name="search" className="form-control search" placeholder="Search..." />
                                            <i className="ri-search-line search-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                <Select
                                placeholder="Filter"
                                name="filter"
                                options={[
                                    {value: null, label: ' All '},
                                    {value: '1', label: 'Active'},
                                    {value: '2', label: 'Inactive'},
                                  ]}
                                onChange={(e) => {
                                    handleSelect(e,'filter')
                                }}
                            />
                                </div>
                                <div className="col-sm">
                                    <div className="d-flex justify-content-sm-end">
                                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createModal"><i className="ri-add-line align-bottom me-1" /> Tambah</button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive table-card mx-2 mt-3 mb-1">
                                <table className="table align-middle table-nowrap" id="customerTable">
                                <thead className="table-light">
                                    <tr>
                                        <th>Nama Pajak</th>
                                        <th>Nilai Pajak</th>
                                        <th>Keterangan Pajak</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                    {
                                        taxData?.rows?.map((v,k)=>{
                                            return(
                                            <tr key={k}>
                                                <td>{v.nama_pajak}</td>
                                                <td>{v.value}</td>
                                                <td>{v.keterangan}</td>
                                                <td>{(v.status_id == 1)? "Active":"Inactive"}</td>
                                                <td>
                                                    <div className="d-flex gap-2">
                                                        <div>
                                                            <button type="button" className="btn btn-sm btn-primary edit-item-btn" onClick={()=>{handleEditData(v)}} data-bs-toggle="modal" data-bs-target="#editModal">Edit</button>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" onChange={(e)=>{changeStatus(e,v.id_pajak)}} checked={(v.status_id == 1) ? true:false} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr> 
                                            )
                                        })
                                    }
                                </tbody>
                                </table>
                            </div>
                            <Pagination 
                                totalData = {taxData.count}
                                params = {param}
                                handleParam = {handleParam}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <Footer/>
</div>
        <EditModalTax
            getData={getData}
            data={editTaxData}
        />
        <CreateModalTax 
            getData={getData}
        />
    </>
    )
}

export default Bank