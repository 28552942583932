import axios from "axios";
import Swal from "sweetalert2";

const ROOT_API = process.env.REACT_APP_API_URL;


export async function getTermOfPayment(params) {
    const URL = `api/admin/top/list`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function editTermOfPayment(data, header) {
  const URL = `api/admin/top/update`;

  const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}

export async function createTermOfPayment(data, header) {
  const URL = `api/admin/top/add`;

  const response = await axios.post(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}

export async function editStatusTermOfPayment(data, header) {
  const URL = `api/admin/top/update/status`;

  const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}