/* eslint-disable jsx-a11y/anchor-is-valid */

const Pagination = (props) => {
    const totalPage = Math.ceil(props.totalData / props.params.params.limit)

    const handleChange = (e) => {
        e.target.name = 'page'
        e.target.value = parseInt(e.target.innerHTML)
        props.handleParam(e)
    }

    return (
        <>
            <div className="d-flex justify-content-end">
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        {
                            props.params.params.page !== 1 && (
                                <>
                                    <li style={{ cursor: 'pointer' }} className="page-item">
                                        <a 
                                            className="page-link" 
                                            onClick={(e) => { 
                                                e.target.name = 'page'
                                                e.target.value = 1
                                                props.handleParam(e) 
                                            }} 
                                            defaultValue={1}
                                        >
                                            First
                                        </a>
                                    </li>
                                    <li style={{ cursor: 'pointer' }} className="page-item">
                                        <a 
                                            className="page-link" 
                                            onClick={(e) => { 
                                                e.target.name = 'page'
                                                e.target.value = parseInt(props.params.params.page-1)
                                                props.handleParam(e) 
                                            }} 
                                            defaultValue={1}
                                        >
                                            Prev
                                        </a>
                                    </li>
                                </>
                            )
                        }
                        {
                            (() => {
                                const arr = []
                                for (let i = 0; i < totalPage; i++) {
                                    arr.push(
                                        <li style={{ cursor: 'pointer' }} className="page-item">
                                            <a
                                                className={`page-link ${((i + 1 === props.params.params.page) ? "bg-primary text-white" : "text-blue")}`}
                                                onClick={(e) => { handleChange(e) }} defaultValue={i + 1}
                                            >
                                                {i + 1}
                                            </a>
                                        </li>
                                    )
                                }
                                return arr.slice(props.params.params.page - 1, props.params.params.page + 4)
                            })()
                        }
                        {
                            props.params.params.page !== totalPage && (
                                <>
                                    <li style={{ cursor: 'pointer' }} className="page-item">
                                    <a 
                                            className="page-link" 
                                            onClick={(e) => { 
                                                e.target.name = 'page'
                                                e.target.value = parseInt(props.params.params.page+1)
                                                props.handleParam(e) 
                                            }} 
                                            defaultValue={props.params.params.page+1}
                                        >
                                            Next
                                        </a>
                                    </li>
                                    <li style={{ cursor: 'pointer' }} className="page-item">
                                    <a 
                                            className="page-link" 
                                            onClick={(e) => { 
                                                e.target.name = 'page'
                                                e.target.value = parseInt(totalPage)
                                                props.handleParam(e) 
                                            }} 
                                            defaultValue={totalPage}
                                        >
                                            Last
                                        </a>
                                    </li>
                                </>
                            )
                        }
                    </ul>
                </nav>
            </div>
            <div className="flex justify-content-end">
                <p className="page-link">Total records: {props.totalData}</p>
            </div>
        </>
    )
}

export default Pagination