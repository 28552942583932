const initialState = {
    email:null,
    role_name:null,
    token:null
}
function TokenReducer(state = initialState, action) {
    switch (action.type) {
        case "ACT_LOGIN":
            return {
                ...state, ...action.payload
            }
        default:
            return state;
    }
}

export default TokenReducer;