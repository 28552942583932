import { useEffect, useState } from "react"
import { useFormik } from "formik";
import { editTermOfPayment } from "../../../Service/Config/TermOfPaymentService";
import { useSelector } from "react-redux";

const EditModalProductCategory = (props) => {
    const {token} = useSelector((state)=>state.TokenReducer)
    const header = {
        headers: { 'Authorization': token }
    }
    const formik = useFormik({
        initialValues:{
            term_of_payment_id:props.data.term_of_payment_id,
            nama_perjanjian:props.data.nama_perjanjian,
            jml_hari:props.data.jml_hari,
            status_id:props.data.status_id,
        },
        enableReinitialize:true
    })

    const handleChange = (e)=>{
        formik.setFieldValue(e.target.name,(e.target.name == "status_id")?(e.target.checked)?1:2:e.target.value)
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        const data = await editTermOfPayment(formik.values,header)
        if (data) {
            // Hide modal with vanila JS
            document.getElementById("editModal").classList.remove('show')
            document.getElementById("editModal").setAttribute('aria-hidden','true')
            document.getElementById("editModal").setAttribute('style','display:none')
            document.body.removeChild(document.getElementsByClassName('modal-backdrop')[0]);
            document.body.style.removeProperty("overflow");
            document.body.style.removeProperty("padding-right");
            document.body.className = '';

            props.getData()
        }
    }

    return (
        <div className="modal fade" id="editModal" tabIndex={-1} aria-labelledby="editModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="editModalLabel">Edit Term Of Payment</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                    <form onSubmit={(e)=>{handleSubmit(e)}}>
                        <input name="term_of_payment_id" type="hidden" value={formik.values.term_of_payment_id} />
                        <div className="mb-3">
                            <label className="form-label">Nama</label>
                            <input type="text" name="nama_perjanjian" value={formik.values.nama_perjanjian} onChange={(e)=>{handleChange(e)}} className="form-control" required/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Hari</label>
                            <input type="number" name="jml_hari" value={formik.values.jml_hari} onChange={(e)=>{handleChange(e)}} className="form-control" required/>
                        </div>
                        {/* <label className="form-label">Status</label>
                        <div className="form-check form-switch">
                            <input className="form-check-input" name="status_id" type="checkbox" role="switch" onChange={(e)=>{handleChange(e)}} checked={(formik.values.status_id == 1) ? true : false} />
                        </div> */}
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}

export default EditModalProductCategory