import axios from "axios";
import Swal from "sweetalert2";

const ROOT_API = process.env.REACT_APP_API_URL;


export async function getUser(params) {
    const URL = `api/master-data/user/list`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function detailUser(id,header) {
    const URL = `api/master-data/user/${id}/detail`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, header);
    const axiosResponse = response;
  
    return axiosResponse.data;
}


export async function editStatusUser(data, header) {
  const URL = `api/master-data/user/change-status`;

  const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}

export async function validatedUser(data, header) {
  const URL = `api/master-data/user/validated`;

  const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
    Swal.fire({
      icon: 'success',
      title: 'success',
      text: "Success"
    })
      return true;
  }).catch((err) => {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: err?.response?.data?.message ?? "Error"
    })
    return false;
  })

  return response;
}