import axios from "axios";
import Swal from "sweetalert2";

const ROOT_API = process.env.REACT_APP_API_URL;

export async function getArmada(params) {
    const URL = `api/master-data/armada/list`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function getArmadaDetail(params) {
    const URL = `api/master-data/armada/${params.params.id_vehicle}/detail`;
  
    const response = await axios.get(`${ROOT_API}${URL}`, params);
    const axiosResponse = response;
  
    return axiosResponse.data;
}

export async function editStatusArmada(data, header) {
    const URL = `api/master-data/armada/change-status`;
  
    const response = await axios.put(`${ROOT_API}${URL}`, data, header).then((response) => {
      Swal.fire({
        icon: 'success',
        title: 'success',
        text: "Success"
      })
        return true;
    }).catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'error',
        text: err?.response?.data?.message ?? "Error"
      })
      return false;
    })
  
    return response;
  }