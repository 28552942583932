import { useSelector, useDispatch } from "react-redux";
import { token_action } from "../../Redux/Action";
import { useNavigate } from "react-router-dom";


const Navbar = ()=>{
  const {role_name, email} = useSelector((state)=>state.TokenReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout = () => {
    const data = {
        id: null,
        email: null,
        token: null,
    }
    dispatch(token_action(data));
    navigate('/login')
  }

    return (
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header topbar-user">
            <button
              type="button"
              className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
            >
              {/* <span className="hamburger-icon">
            <span />
            <span />
            <span />
          </span> */}
            </button>
            {/* App Search*/}

            <div className="d-flex align-items-center">
              <div className="dropdown ms-sm-3 header-item topbar-user">
                <button
                  type="button"
                  className="btn"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-flex align-items-center">
                    <span className="text-start ms-xl-2">
                      <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {email}
                      </span>
                      <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                        {role_name}
                      </span>
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <div
                    className="dropdown-item cursor-pointer"
                    onClick={handleLogout}
                  >
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />{" "}
                    <span className="align-middle" data-key="t-logout">
                      Logout
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
}

export default Navbar;