import Navbar from "../../../Components/Layout/Navbar"
import Sidebar from "../../../Components/Layout/Sidebar"
import Footer from "../../../Components/Layout/Footer"
import { useEffect, useState } from "react"
import { getDriver } from "../../../Service/Report/DriverService";
import { useSelector } from "react-redux";
// import EditModalTax from "./EditModalTax";
// import CreateModalTax from "./CreateModalTax";
import Select from 'react-select';
import Pagination from "../../../Components/Pagination";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Driver = ()=>{
    const {token} = useSelector((state)=>state.TokenReducer)
    const [driverData, setDriverData] = useState([])
    const navigate = useNavigate()
    const [param,setParam] = useState({
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            search:null
        }
    })
    let configs = param
    let header = {
        headers: { 'Authorization': token },
    }

    const handleParam = (e) =>{
        configs.params = {
            ...configs.params,
            [e.target.name]:e.target.value
        }
        getData()
    }
    const handleSelect = (e, name) =>{
        configs.params = {
            ...configs.params,
            [name]:e.value
        }
        getData()
    }

    const getData = async()=>{
        const data = await getDriver(configs)
        setDriverData(data)
    }

    useEffect(()=>{
        getData()
    },[])
    return(
    <>
        <Navbar/>
        <Sidebar/>
<div className="main-content">
  <div className="page-content">
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 mb-3">
                <h4 className="mb-sm-0 mb-1">Driver</h4>
                <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">Master Data</li>
                    <li className="breadcrumb-item active">Driver</li>
                </ol>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div id="customerList">
                            <div className="row g-4 mb-3">
                                <div className="col-sm-auto">
                                    <div>
                                        <div className="search-box ms-2">
                                            <input type="text" onChange={(e)=>{handleParam(e)}} name="search" className="form-control search" placeholder="Search..." />
                                            <i className="ri-search-line search-icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                <Select
                                    placeholder="Filter"
                                    name="filter"
                                    options={[
                                        {value: null, label: ' All '},
                                        {value: "active", label: 'Active'},
                                        {value: "inactive", label: 'Inactive'},
                                    ]}
                                    onChange={(e) => {
                                        handleSelect(e,'filter')
                                    }}
                                />
                                </div>
                            </div>
                            <div className="table-responsive table-card mx-2 mt-3 mb-1">
                                <table className="table align-middle table-nowrap" id="customerTable">
                                <thead className="table-light">
                                    <tr>
                                        <th>Nama</th>
                                        <th>No Handphone</th>
                                        <th>Transporter</th>
                                        <th>Vendor</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                    {
                                        driverData?.rows?.map((v,k)=>{
                                            console.log(v);
                                            return(
                                            <tr key={k}>
                                                <td onClick={()=>{navigate(`/driver/${v.driver_id}/order`)}} className="text-primary cursor-pointer">{v.nama_driver}</td>
                                                <td>{v.no_hp}</td>
                                                <td>{v.user?.nama}</td>
                                                <td>{v.driver_vendor?.nama_vendor}</td>
                                                <td>{(v.status_id === 1)? "Active":"Inactive"}</td>
                                            </tr> 
                                            )
                                        })
                                    }
                                </tbody>
                                </table>
                            </div>
                            <Pagination 
                                totalData = {driverData.count}
                                params = {param}
                                handleParam = {handleParam}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <Footer/>
</div>
        {/* <EditModalTax
            getData={getData}
            data={editTaxData}
        />
        <CreateModalTax 
            getData={getData}
        /> */}
    </>
    )
}

export default Driver