const Footer = ()=>{
    return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6">
                    <script>document.write(new Date().getFullYear())</script> © dport.id.
                </div>
                <div className="col-sm-6">
                    <div className="text-sm-end d-none d-sm-block">
                        Design & Develop by BIG
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer;