import { useEffect, useState } from "react"
import { useFormik } from "formik";
import { editVehicleType, detailVehicleType } from "../../../Service/Config/VehicleTypeService";
import { getVehicleBrand } from "../../../Service/Config/VehicleBrandService";
import { useSelector } from "react-redux";
import AsyncSelect from 'react-select/async'

const EditModalVehicleType = (props) => {
    const { token } = useSelector((state) => state.TokenReducer)
    const [vehicle, setVehicle] = useState({
        id_vehicle_type: props.data.id_vehicle_type,
        jenis_kendaraan: props.data.jenis_kendaraan,
        model_kendaraan: props.data.model_kendaraan,
        keterangan: props.data.keterangan,
        l_dimensi: props.data.l_dimensi,
        model_kendaraan: props.data.model_kendaraan,
        p_dimensi: props.data.p_dimensi,
        t_dimensi: props.data.t_dimensi,
        status_id: props.data.status_id,
        brand_name: null,
    })
    const header = {
        headers: { 'Authorization': token }
    }
    let configs = {
        headers: { 'Authorization': token },
        params: {
            limit: 10,
            page: 1,
            search: null
        }
    }
    const formik = useFormik({
        initialValues: vehicle,
        enableReinitialize: true
    })

    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value)
    }

    const getDetailVehicleType = async () => {
        const data = await detailVehicleType(props.data.id_vehicle_type, header)
        setVehicle({
            ...vehicle,
            id_vehicle_type: data.data.id_vehicle_type,
            jenis_kendaraan: data.data.jenis_kendaraan,
            model_kendaraan: data.data.model_kendaraan,
            brand_name: data.data.vehicle_brand.brand_name,
            keterangan: data.data.keterangan,
            p_dimensi: data.data.p_dimensi,
            l_dimensi: data.data.l_dimensi,
            t_dimensi: data.data.t_dimensi,
            status_id: data.data.status_id,
        })
        formik.setFieldValue('brand_name', data.data.vehicle_brand.brand_name)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = await editVehicleType(formik.values, header)
        if (data) {
            // Hide modal with vanila JS
            document.getElementById("editModal").classList.remove('show')
            document.getElementById("editModal").setAttribute('aria-hidden', 'true')
            document.getElementById("editModal").setAttribute('style', 'display:none')
            document.body.removeChild(document.getElementsByClassName('modal-backdrop')[0]);
            document.getElementById("editModal").removeAttribute("aria-modal");
            document.body.style.removeProperty("overflow");
            document.body.style.removeProperty("padding-right");
            document.body.className = '';

            props.getData()
        }
    }

    const handleSelect = (e, name) => {
        formik.setFieldValue(name, e.brand_id)
    }

    const optionBrand = async inputValue => {
        const data = await getVehicleBrand(configs)
        return data.rows
    }

    useEffect(() => {
        if (props.data?.id_vehicle_type) {
            getDetailVehicleType()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <div className="modal fade" id="editModal" tabIndex={-1} aria-labelledby="editModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editModalLabel">Edit Vehicle Type</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e) => { handleSubmit(e) }}>
                            <input name="id_vehicle_type" type="hidden" value={formik.values.id_vehicle_type} />
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Jenis Kendaraan</label>
                                        <input type="text" name="jenis_kendaraan" value={formik.values.jenis_kendaraan} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Model Kendaraan</label>
                                        <input type="text" name="model_kendaraan" value={formik.values.model_kendaraan} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Keterangan</label>
                                        <input type="text" name="keterangan" value={formik.values.keterangan} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <label className="form-label">Status</label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" name="status_id" type="checkbox" role="switch" onChange={(e) => { handleChange(e) }} checked={(formik.values.status_id === 1) ? true : false} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Panjang</label>
                                        <input type="text" name="p_dimensi" value={formik.values.p_dimensi} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Lebar</label>
                                        <input type="text" name="l_dimensi" value={formik.values.l_dimensi} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Tinggi</label>
                                        <input type="text" name="t_dimensi" value={formik.values.t_dimensi} onChange={(e) => { handleChange(e) }} className="form-control" required />
                                    </div>
                                </div>
                            </div>


                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditModalVehicleType